// ARTICLES

.skeleton {
  width: 100%;
  height: 100%;
  animation: skeleton 1.5s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.articleFeatured-image,
.articleThumbnail-image,
.articleCard-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    z-index: 2;
    transition: transform 0.2s ease;
    position: absolute;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.article {
  display: block;
  transition: all $general-change;
}

// Article Featured

.articleFeatured {
  $self: &;
  position: relative;
  &-link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .button {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  &-info {
    padding: 1.25rem;
    background: $purple-900;
    color: $white;
  }

  &:hover {
    cursor: pointer;
    #{$self}-info {
      background: $purple-800;
    }
  }

  &-title {
    @include typo-rwd(stapel-step-up-2);
    margin-bottom: 0.75rem;
  }

  &-text {
    @include typo-rwd(dm-sans-step-up-1-regular);
    opacity: 0.87;
    margin-bottom: 16px;
  }

  &-image {
    height: 12rem;
  }

  @include mq(md) {
    display: flex;
    &-image {
      flex: 1 1 auto;
      height: auto;
      min-height: 22.5rem;
    }

    .button {
      width: auto;
    }

    &-info {
      padding: 2.5rem 1.5rem;
      flex: 0 0 19.4375rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-text {
      margin-bottom: 28px;
    }
  }
  @include mq(lg) {
    &-info {
      flex-basis: 25.875rem;
      padding: 1.875rem 2.5rem 1.875rem 1.875rem;
    }

    &-title {
      @include typo-rwd(stapel-step-up-3);
    }

    &-image {
      min-height: 22.125rem;
    }
  }

  @include mq(xl) {
    &-info {
      flex-basis: 33.3125rem;
    }

    &-title {
      @include typo-rwd(stapel-step-up-4);
    }
  }

  @include mq(xxl) {
    &-image {
      height: 40rem;
    }
  }
}

// Article Thumbnail

.articleThumbnail {
  $self: &;
  background: $white;

  &.is-small {
    #{$self}-info {
      padding: 12px 16px;
    }
  }

  &:hover {
    background: $gray-50;
  }

  &-title {
    @include typo-rwd(stapel-step-up-2);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &-text {
    @include typo-rwd(dm-sans-baseline-regular);
    color: $gray-600;
    margin: 0.5rem 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &-info {
    padding: 0.75rem 1rem 1.25rem 1rem;
  }

  &-image {
    width: 100%;
    // Mantain aspect ratio
    padding-top: 56%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @include mq(lg) {
    max-width: 33.3%;
    &.is-small {
      #{$self}-info {
        padding: 1.25rem 1rem;
      }
    }
  }

  @include mq(xl) {
    &-text {
      -webkit-line-clamp: 2;
    }

    &-info {
      padding: 1.6875rem 2rem;
    }
  }
}

// Article Card

.articleCard {
  max-width: 58.375rem;
  border-bottom: 0.0625rem solid $gray-100;

  &-text {
    @include typo-rwd(dm-sans-baseline-regular);
    color: $gray-600;
    margin: 0.5rem 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &-title {
    @include typo-rwd(stapel-step-up-2);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  &-info {
    padding: 0.75rem 1rem 1.25rem 1rem;
  }

  &-image {
    img {
      position: relative;
    }
  }

  &:hover {
    background: $gray-50;
  }

  @include mq(md) {
    display: flex;
    padding: 1.75rem 0 1.75rem 1rem;
    border: 0.0625rem solid $gray-100;

    + .articleCard {
      margin-top: -0.0625rem;
    }

    &-info {
      order: -1;
      flex: 1 1 auto;
    }

    &-image {
      flex: 0 0 18.75rem;
      width: 18.75rem;
      margin-left: 1.75rem;
    }
  }

  @include mq(lg) {
    padding: 2.625rem 0 2.625rem 1.75rem;

    &-text {
      -webkit-line-clamp: 4;
    }

    &-title {
      @include typo-rwd(stapel-step-up-3);
    }

    &-image {
      flex-basis: 28.125rem;
      width: 28.125rem;
    }
  }

  @include mq(xl) {
    &-image {
      flex-basis: 29.1875rem;
      width: 29.1875rem;
    }
  }
}

.articleCards-actions {
  padding: 3rem 0;
  text-align: center;
  button {
    overflow-anchor: none;
  }
}

// Article Thumbnails

.articleThumbnails {
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    .articleThumbnail {
      flex: 0 1 50%;
    }
  }
  @include mq(lg) {
    .articleThumbnail {
      flex-basis: 33.3%;
      padding-bottom: 40px;
    }
  }
}

// Like counter

.likeCounter {
  $self: &;
  display: flex;
  align-items: center;
  color: $gray-900;

  &-printWrapper {
    margin-right: 8px;
    position: relative;
    text-align: center;
    padding: 30px 0;
  }

  &-bubble {
    opacity: 0;
    @include typo-rwd(dm-sans-step-down-1-bold);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: $gray-900;
    border-radius: 50%;
    color: $white;
    position: absolute;
    top: 0;
    left: 10px;
  }

  &-print {
    position: relative;
    transition: opacity $general-change;
    height: 48px;
    width: 48px;
  }

  &-icon,
  &-iconColor {
    width: 48px;
    height: 48px;
  }

  &-iconBw {
    height: 48px;
    position: absolute;
    bottom: 0;
    .likeCounter-icon {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &-iconColor {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;

    .likeCounter-icon {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &-text {
    @include typo-rwd(dm-sans-step-down-2-bold);
  }

  &-counter {
    @include typo-rwd(dm-sans-step-down-1-regular);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 62px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    cursor: pointer;

    &:not(.is-active) {
      #{$self}-print {
        opacity: 0.7;
      }
    }
  }

  &.is-active {
    #{$self}-iconBw {
      animation: fade-out 1s ease forwards;
    }

    #{$self}-iconColor {
      animation: slide-up 0.5s ease-in-out forwards;
      background-size: 47px 56px;
    }

    #{$self}-bubble {
      animation: fade-in 1s ease-in, fade-top 0.5s 0.5s ease-in-out forwards;
    }

    #{$self}-print {
      animation: scale-up-down 0.5s ease-in-out forwards;
    }
  }
}

// PlayerTop

.player {
  display: block;
  display: flex;
  align-items: center;
  text-align: left;
  min-width: 0;
  transition: all $color-change ease;

  &:hover {
    background: $gray-50;
  }

  .icon {
    width: 1rem;
  }

  &-avatar {
    flex: 0 0 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;

    .avatar {
      width: 48px;
      height: 48px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &-info {
    flex: 1 1 auto;
    min-width: 0;
  }

  &-platformName {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-userName {
    @include typo-rwd(dm-sans-step-down-1-regular);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-value {
    @include typo-rwd(stapel-step-up-2);
    text-align: center;
    text-transform: uppercase;
    min-width: 3.75rem;
  }

  &-console {
    @include typo-rwd(dm-sans-step-down-1-regular);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    .icon {
      margin-right: 0.125rem;
      height: 1rem;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
  }
}

// Player Top

.playerTop {
  background: $dark-blue-900;
  color: $white;
  padding: 1rem;

  .player-userName {
    opacity: 0.8;
  }

  .player-avatar {
    .avatar {
      fill: $white;
    }
  }

  .player-platformName {
    margin-bottom: 0.125rem;
  }

  &:hover {
    background: $dark-blue-700;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid $dark-blue-600;
  }
}

// Player Result

.playerResult {
  background: $white;
  padding: 0.875rem 1rem;
  border-top: 0.0625rem solid $gray-200;
  border-bottom: 0.0625rem solid $gray-200;

  .player-platformName {
    margin-bottom: 0.125rem;
  }

  .player-userName {
    color: $gray-600;
  }

  .player-value {
    padding-left: 1.25rem;
    min-width: 4.5rem;
    text-align: right;
  }

  + .playerResult {
    margin-top: -0.0625rem;
  }

  .player-extra {
    @include typo-rwd(dm-sans-step-down-1-regular);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $gray-400;
  }

  .player-userName,
  .player-extra {
    margin-bottom: 0.0625rem;
  }

  &.status {
    padding: 1rem;
    align-items: flex-start;
    &:hover {
      background: $gray-50;
    }
  }

  .player-actions {
    padding-left: 0.625rem;
  }

  .chip {
    margin-left: 0.75rem;
  }

  @include mq($until: md) {
    .chip {
      height: 1.1875rem;
      padding: 0 0.5rem;
      margin-bottom: 0.25rem;
    }

    .chip-text {
      font-size: 0.625rem;
    }

    .player-actions {
      flex-direction: column;
      align-items: flex-end;
    }

    .player-value {
      padding-left: 0;
      min-width: 0;
    }

    .player-actions {
      padding-left: 1.25rem;
    }
  }
}

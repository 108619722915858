// Footer

.footer {
  background: $dark-blue-900;
  color: $white;

  .genericLabel {
    color: rgba($white, 0.8);
  }

  &-partner {
    display: block;
    transition: all $color-change ease;

    &:hover {
      opacity: 0.6;
    }
  }

  &-partners {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &-link {
    background: transparent;
    border: none;
    outline: none;
    @include typo-rwd(dm-sans-step-down-1-bold);
    display: block;
    padding: 0 0 16px 0;
    transition: color $color-change ease;
    &:hover {
      color: $blue-400;
      cursor: pointer;
    }
  }

  .neox {
    img {
      width: 157px;
    }
  }

  &-extraLink,
  &-copy {
    @include typo-rwd(dm-sans-step-down-2-regular);
    opacity: 0.8;
  }

  &-extraLink {
    padding: 0 8px 8px 0;
    display: inline-block;
    transition: color $color-change ease;

    &:hover {
      color: $blue-400;
    }

    &:not(:last-of-type) {
      &:after {
        content: '';
        width: 1px;
        height: 10px;
        background: rgba($white, 0.8);
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
      }
    }
  }

  @include mq($until: lg) {
    text-align: center;
    padding: 36px 26px 20px;

    &-links {
      margin-bottom: 8px;
    }

    &-partner {
      padding: 0 20px;
    }

    &-partners {
      justify-content: center;
      padding: 60px 0;
    }

    &-copy {
      display: block;
      margin-top: 24px;
    }
  }

  @include mq(lg) {
    &-main {
      display: flex;
    }

    &-links {
      flex: 0 0 auto;
      padding-right: 48px;
    }

    &-extra {
      display: flex;
    }

    &-copy {
      margin-left: auto;
    }
  }

  @include mq(lg) {
    padding: 30px 26px 16px;

    &-main {
      padding-bottom: 60px;
    }

    &-partner {
      margin-left: 30px;
    }
  }
}

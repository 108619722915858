// Dropdown

.dropdown {
  $self: &;

  &-list {
    width: 100%;
    box-shadow: $elevation2;
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    background: $white;
    color: $dark-blue-900;
    min-width: 178px;
    max-width: 210px;

    &-user {
      top: 50px;
      right: 0;
    }
    &-competitions {
      top: 60px;
      left: 340px;
    }
  }

  &-trigger {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    outline: none;
    &:hover {
      color: $blue-400;
    }

    .icon {
      transition: transform $general-change;
      width: 24px;
      height: 24px;
      margin-left: 12px;
      margin-top: 2px;
    }
  }

  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    @include typo-rwd(dm-sans-baseline-bold);
    color: $dark-blue-900;
    padding: 16px;
    border: none;
    background: transparent;
    transition: all $general-change;
    text-align: left;

    &:hover {
      background: $gray-100;
    }

    .icon {
      margin-left: 12px;
      width: 24px;
      height: 24px;
    }
  }

  &.open {
    #{$self}-trigger {
      .icon {
        transform: rotate(180deg);
      }
    }
    #{$self}-list {
      animation: fade-in $general-change;
      display: block;
    }
  }
}

// Record card

.recordCard {
  $self: &;
  display: flex;
  align-items: center;

  + .recordCard {
    margin-top: 1.125rem;
  }

  &-image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;
    margin-right: 16px;
    background: $gray-100;
    flex: 0 0 48px;
  }

  &-level1 {
    background: linear-gradient(
      180deg,
      rgba(255, 229, 9, 1) 0%,
      rgba(255, 212, 5, 1) 75%,
      rgba(254, 191, 0, 1) 100%
    );
  }

  &-level2 {
    background: linear-gradient(
      180deg,
      rgba(235, 239, 241, 1) 0%,
      rgba(216, 223, 225, 1) 75%
    );
  }

  &-level3 {
    background: linear-gradient(
      180deg,
      rgba(254, 191, 0, 1) 0%,
      rgba(254, 171, 0, 1) 75%
    );
  }

  &-text {
    flex: 1 1 auto;
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-900;
    text-align: left;
  }
}

// Pagination

.pagination {
  display: flex;
  align-items: center;
  padding: 1.25rem 0;

  &-text {
    @include typo-rwd(stapel-step-up-1);
    padding: 0 1.75rem;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    transition: all $color-change ease;
    &:hover {
      color: $blue-400;
    }
  }

  .iconWrapper {
    line-height: 0;
  }

  .button-icon {
    &.is-disabled {
      opacity: 0.2;
      cursor: initial;
    }
  }
}

// Heading banner

.headingBanner {
  background: $dark-blue-900;
  color: $white;
  text-align: center;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 3rem;

  &-title {
    @include typo-rwd(stapel-step-up-2);
    max-width: 600px;
    padding: 0 16px;

    &.is-big {
      @include typo-rwd(stapel-step-up-3);
    }
  }

  &-subtitle {
    @include typo-rwd(dm-sans-step-down-1-bold-caps);
    text-transform: uppercase;
    margin: 1.25rem 0;
    &:before,
    &:after {
      content: '-';
      margin: 0 0.25rem;
      position: relative;
      bottom: 0.125rem;
      display: inline-block;
    }
  }
  &-caption {
    @include typo-rwd(dm-sans-step-down-1-regular);
  }

  @include mq(md) {
    min-height: 12.5rem;
  }

  @include mq(lg) {
    padding-bottom: 4rem;

    &-title {
      @include typo-rwd(stapel-step-up-3);

      &.is-big {
        @include typo-rwd(stapel-step-up-4);
      }
    }
  }
}

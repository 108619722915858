// Portrait

.portrait {
  $self: &;
  $width-big-sm: 15rem;
  $width-big-l: 18.75rem;
  $width-big-xl: 26.375rem;
  $height-big-sm: 19.4375rem;
  $height-big-l: 24.3125rem;
  $height-big-xl: 34.125rem;
  $width-sm: 11.25rem;
  $width-l: 13.75rem;
  $width-xl: 16.875rem;
  $height-sm: 14.5625rem;
  $height-l: 17.8125rem;
  $height-xl: 21.875rem;
  text-align: center;
  display: inline-block;

  &.is-big {
    #{$self}-title {
      @include typo-rwd(stapel-step-up-3);
    }

    #{$self}-avatar {
      width: $width-big-sm;
      height: $height-big-sm;
    }

    .player {
      max-height: calc(#{$height-big-sm} - 2.5rem);
    }
  }

  &.is-tiny {
    #{$self}-avatar {
      width: 4.375rem;
      height: 5.625rem;
      &:hover {
        &:before {
          display: none;
        }
      }
    }
    .player {
      max-height: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    margin-bottom: 1.25rem;
    @include typo-rwd(stapel-step-up-2);
  }

  &-avatar {
    display: block;
    position: relative;
    width: $width-sm;
    height: $height-sm;
    -webkit-mask-image: url('../../../assets/images/frame-complete.svg');
    mask-image: url('../../../assets/images/frame-complete.svg');
    .frame {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 2;
    }

    .frame-bg {
      @include centered(absolute);
      z-index: 0;
      width: 100%;
    }

    .player {
      position: absolute;
      z-index: 1;
      width: 96%;
      max-height: calc(#{$height-sm} - 2.5rem);
      object-fit: contain;
      @include centered(absolute);
    }

    .hover-text {
      @include centered(absolute);
      color: $white;
      z-index: 2;
      font-weight: $bold;
      font-size: 16px;
      opacity: 0;
      &:hover {
        color: $blue-400;
      }
    }

    &:hover {
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0, 4, 41, 0.7);
        position: absolute;
        top: 0;
        bottom: 0.625rem;
        left: 0;
        z-index: 2;
        transition: all $general-change;
      }
      .hover-text {
        opacity: 1;
      }
    }
  }

  &-userName,
  &-name {
    @include typo-rwd(dm-sans-step-up-1-bold);
    margin-bottom: 0.25rem;
  }

  &-consoleId,
  &-position {
    @include typo-rwd(dm-sans-baseline-regular);
    opacity: 0.8;
  }

  &-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.25rem;
  }

  &-info {
    margin: 1.25rem 0;
  }

  .socialItem {
    &:not(:last-child) {
      margin-right: 1.25rem;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
    .bg {
      fill: $dark-blue-600;
    }

    &:hover {
      .bg {
        fill: $white;
      }

      .shape {
        fill: $dark-blue-600;
      }
    }
  }

  &.is-squared {
    #{$self}-avatar {
      -webkit-mask-image: none;
      mask-image: none;
      width: 270px;
      height: 299px;
      border-radius: 10px;
      overflow: hidden;
      &:hover {
        &:before {
          display: none;
        }
      }

      .player {
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        max-height: none;
        object-fit: cover;
      }
    }
  }

  @include mq(md) {
    &-avatar {
      width: $width-l;
      height: $height-l;
    }

    .player {
      max-height: calc(#{$height-l} - 2.5rem);
    }

    &-title {
      @include typo-rwd(stapel-step-up-3);
      margin-bottom: 1.75rem;
    }

    &.is-big {
      #{$self}-avatar {
        width: $width-big-l;
        height: $height-big-l;
      }

      #{$self}-title {
        @include typo-rwd(stapel-step-up-4);
      }

      .player {
        max-height: calc(#{$height-big-l} - 2.5rem);
      }
    }
  }

  @include mq(lg) {
    &.is-tiny {
      #{$self}-avatar {
        width: 112px;
        height: 145px;
      }
    }
  }

  @include mq(xl) {
    &-avatar {
      width: $width-xl;
      height: $height-xl;
    }

    .player {
      max-height: calc(#{$height-xl} - 2.5rem);
    }

    &.is-big {
      #{$self}-avatar {
        width: $width-big-xl;
        height: $height-big-xl;
      }

      .player {
        max-height: calc(#{$height-big-xl} - 2.5rem);
      }
    }
  }
}

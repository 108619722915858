// Lists

.iconList {
  $self: &;
  &-wrapper {
    padding-top: 2.5rem;
  }

  &-title {
    text-align: center;
    margin-bottom: 0.75rem;
  }

  &-edit {
    padding-bottom: 12px;
    #{$self}-title {
      text-align: left;
      margin-bottom: 1.25rem;
    }

    .button {
      margin-top: 2rem;
    }
  }

  &-item {
    border-top: 1px solid $gray-200;
    &:last-child {
      border-bottom: 1px solid $gray-200;
    }

    .bg {
      fill: $gray-50;
    }

    .shape {
      fill: $gray-900;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .iconWrapper {
    margin-right: 0.75rem;
  }

  &-username,
  &-name {
    flex: 1 1 auto;
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-900;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    min-width: 0;
  }

  &-actions {
    display: flex;
    .iconWrapper {
      margin: 0;
    }

    .icon {
      width: 1.25rem;
      height: 1.25rem;
    }

    .button-icon {
      width: 60px;
      &:hover {
        color: $blue-400;
      }
    }
  }

  &-heading {
    display: flex;
  }

  &-linkHint {
    @include typo-rwd(dm-sans-step-down-2-bold);
    color: $blue-800;
    min-width: 4rem;
    padding-left: 0.625rem;
  }

  &-content {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    transition: all $general-change;
  }
  a {
    &:hover {
      background: $gray-50;
    }
  }

  &-emptyText {
    @include typo-rwd(dm-sans-step-up-1-regular);
    color: $gray-400;
  }

  &-headingLabel {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    color: $gray-400;
    padding: 0.75rem 0;

    &.is-long {
      flex: 1 1 auto;
    }

    &.is-short {
      text-align: center;
      flex: 0 0 60px;
    }
  }

  @include mq(md) {
    &-content {
      padding: 0.75rem;
    }
    &-actions {
      .button-icon {
        width: 5.75rem;
        margin-right: -0.75rem;
      }
    }

    &-headingLabel {
      @include typo-rwd(dm-sans-step-down-2-bold-caps);
      padding: 0.75rem;

      &.is-short {
        flex: 0 0 calc(5.75rem - 0.5rem);
      }
    }
  }
}

// Report result

.reportResult {
  padding: 0 16px;
  &-actions {
    padding: 3.25rem 1rem;
    .button {
      width: 100%;
      margin-bottom: 0.75rem;
    }
  }
}

// Button disabled loading and success styles

.reportResult,
.reportResult-actions {
  .button {
    &--primary {
      &[disabled] {
        &.is-success {
          background: $green-800;
          border-color: $green-800;
          color: $white;
          animation: success-to-disabled 3s ease-in-out forwards;
        }
        &.is-loading {
          background: $red-600;
          border-color: $red-600;
          color: $white;
        }
      }
    }
  }
}

.formReportResult {
  padding: 4rem 0 3rem 0;

  .radioGroup {
    margin-top: 1.5rem;
    margin: 1.5rem 0 2.5rem;
  }

  .errorCard {
    margin-top: 1rem;
  }
}

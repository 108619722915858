// User

.user {
  display: flex;
  align-items: center;
  position: relative;
  display: block;

  &-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.75rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &-name {
    display: flex;
    align-items: center;
    .icon {
      width: 40px;
      height: 40px;
    }
  }

  .name {
    font-size: 1rem;
    font-weight: $bold;
    letter-spacing: 0.0125rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }

  .button-icon {
    transition: transform $general-change;
    &:hover {
      .icon {
        color: $blue-400;
      }
    }
  }

  @include mq(xxl) {
    .name {
      max-width: 12.5rem;
    }
  }
}

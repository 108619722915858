// Steps

.competitionStep {
  text-align: left;
  max-width: 21.25rem;
  margin: 0 auto;
  .number {
    @include typo-rwd(stapel-step-up-2);
  }
  .step {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $elevation2;
    border-radius: 50%;
    margin: 1.5rem auto;
    z-index: 2;
    position: relative;
    background: $white;
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
    margin-bottom: 4px;
  }

  .step-text {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    margin-bottom: 1.125rem;
  }

  @include mq(md) {
    padding: 0 1.25rem;
    max-width: 18.75rem;
  }

  @include mq(lg) {
    padding: 0 2.5rem;
  }
}

.step-infoPiece {
  margin-bottom: 1.125rem;
  .label {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    color: $gray-900;
    text-transform: uppercase;
  }

  .value {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
  }
}

.competitionSteps {
  position: relative;
  max-width: 68.75rem;
  margin: 0 auto;

  @include mq(xl) {
    display: flex;
    justify-content: space-around;

    .has-icon {
      border: 7px solid $green-300;
    }

    &:after {
      content: '';
      width: calc(100% - 120px);
      max-width: calc(1200px - 300px);
      height: 0.5rem;
      background: linear-gradient(
        90deg,
        rgba(0, 195, 255, 1) 35%,
        rgba(26, 255, 130, 1) 100%
      );

      position: absolute;
      top: 3.125rem;
      left: 7.5rem;
      z-index: 1;
    }

    .competitionStep {
      flex: 0 0 18.5rem;
    }
  }
  @include mq(lg) {
    padding-bottom: 3.125rem;
  }
}

// CompetitionSteps TGA

.competitionSteps {
  &-tga {
    padding: 2.5rem 1rem;
    .stepUp4-title {
      text-align: center;
      max-width: 43.75rem;
      margin: 0 auto 2.5rem;
    }

    .competitionStep {
      padding: 0 0 2.5rem;
      margin: 0;
      max-width: none;
    }

    .step {
      margin: 0 0 1.25rem 0;
    }

    @include mq(md) {
      padding: 4.25rem 0;
      .steps {
        max-width: 47.5rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
      }
      .competitionStep {
        flex: 0 0 50%;
        max-width: 23.75rem;
        padding: 0 0.75rem 2.5rem;
      }
    }
  }
}

// Layout

.mainLayout,
.formLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .mainContent {
    flex: 1 1 auto;
    overflow-wrap: break-word;
  }

  .barSlot {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 11;
    text-align: right;
  }

  .mainNav,
  .simpleNav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  &.has-outer-wrapper {
    background: $gray-50;
    .mainNav {
      background: $dark-blue-900;
    }

    .mainContent {
      background: $white;
    }

    .mainContent,
    .footer {
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
    }
  }
}

.mainLayout {
  &.isLight {
    .mainNav {
      background: $dark-blue-900;
    }
  }
  .barSlot {
    text-align: right;
  }
}

.formLayout {
  .barSlot {
    top: 68px;
  }
  .mainContent {
    padding-top: 68px;
    border-top: 1px solid $gray-100;
  }

  @include mq(md) {
    .mainContent {
      padding-top: 80px;
    }
    .barSlot {
      top: 80px;
    }
  }

  @include mq(lg) {
    .mainContent {
      padding-top: 100px;
    }
    .barSlot {
      top: 100px;
    }
  }
}

// Info Piece

.infoPiece {
  text-align: center;
  padding: 1.75rem 1rem;
  max-width: 25rem;
  margin: 0 auto;

  .rounded-image {
    margin: 0 auto 1.25rem;
  }

  &-label {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    color: $gray-400;
  }

  &-title {
    @include typo-rwd(dm-sans-step-up-1-bold);
    margin-bottom: 0.3125rem;
    color: $dark-blue-900;
  }

  &-description {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
  }

  &-result {
    text-align: left;
    padding: 0;
  }

  &-value {
    @include typo-rwd(stapel-step-up-3);
  }

  &-detail {
    width: 80%;
    height: 10px;
    display: block;

    &.success {
      background: $green-400;
    }

    &.fail {
      background: $red-400;
    }
  }

  @include mq(md) {
    padding: 2.5rem;
    &-group {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 83.25rem;

      .infoPiece {
        flex: 0 0 50%;
      }
    }
    &-result {
      padding: 0;
    }
  }

  @include mq(lg) {
    &-group {
      .infoPiece {
        flex: 0 0 33.3%;
      }
    }
  }
}

.is-dark {
  .infoPiece {
    &-title,
    &-description {
      color: $white;
    }
    &-description {
      opacity: 0.8;
    }
  }
}

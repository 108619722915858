// Inputs and textarea

input {
  &[type='text'],
  &[type='password'],
  &[type='number'],
  &[type='email'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}

.input,
.textarea {
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &-input:focus + .input-label,
  &.has-value .input-label,
  &-input:focus + .textarea-label,
  &.has-value .textarea-label {
    @include typo-rwd(dm-sans-step-down-2-regular);
    transform: translateY(-50%);
  }

  &.has-error .input-input,
  &.has-error .textarea-input {
    border: 0.125rem solid $red-700;
  }

  &.is-disabled {
    pointer-events: none;

    .input-input {
      color: $gray-300;
      border: 0.0625rem solid $gray-300;
    }

    .input-label {
      color: $gray-300;
    }
  }

  &-input {
    @include typo-rwd(dm-sans-baseline-regular);
    display: block;
    position: relative;
    margin: 0;
    padding: 1rem 1rem 1.125rem 1rem;
    width: 100%;
    height: 3.5rem;
    color: $gray-900;
    border: 1px solid $gray-300;
    border-radius: 0.25rem;
    outline: none;
    transition: border $color-change;

    &:hover {
      border: 1px solid $gray-600;
    }

    &:focus {
      border: 0.125rem solid $purple-500;
    }
  }

  &-label {
    @include typo-rwd(dm-sans-baseline-regular);
    display: inline-block;
    position: absolute;
    padding: 0 0.25rem;
    top: 0;
    left: 0.75rem;
    color: $gray-600;
    background-color: $white;
    box-sizing: border-box;
    pointer-events: none;
    transform: translateY(1rem);
    transition: all $small-item-change;
  }
}

.inlineInputs {
  &-field {
    display: flex;

    .input {
      flex: 1 1 50%;
      &:last-child {
        margin-left: 1.25rem;
      }
    }
  }
}

.textarea {
  &-input {
    height: 8.75rem;
    resize: vertical;
  }
}

.dateInputs {
  &-label {
    display: block;
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    margin-bottom: 1rem;
  }

  &-inputs {
    display: flex;
    align-items: flex-start;

    .select {
      flex: 1 1 auto;
      margin: 0 1.25rem;
    }
    .input {
      flex: 0 0 15%;
      &:last-child {
        flex-basis: 25%;
      }
    }
  }
}

// DIVISIONAL CHAMPIONS

.divisionalChampions {
  $self: &;
  padding: 2rem 1rem;

  &-title {
    text-align: center;
    @include typo-rwd(stapel-step-up-3);
    margin-bottom: 2rem;
  }

  &-subtitle {
    @include typo-rwd(stapel-step-up-2);
    margin-bottom: 1.25rem;
  }

  &-list {
    margin-bottom: 1rem;
    min-width: 0;
  }

  .wrapper {
    max-width: 72.75rem;
  }

  &-action {
    text-align: center;
    margin: 2.25rem 0;
  }

  @include mq(md) {
    padding: 2.5rem 1rem;
    &-title {
      padding-left: 0.625rem;
    }

    &-subtitle {
      padding-left: 0.625rem;
    }

    &-lists {
      display: flex;
    }

    &-list {
      flex: 0 1 50%;
      &:first-child {
        padding-right: 0.625rem;
      }
    }
  }
  @include mq(lg) {
    padding: 3.25rem 1rem;

    &-title {
      @include typo-rwd(stapel-step-up-4);
      margin-bottom: 2.5rem;
    }

    &-action {
      margin: 4.3125rem 0;
    }
  }
}

// Tabs

.tabs {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;

  &-wrapper {
    padding: 2rem 1rem;
    position: relative;
  }

  .tabItem {
    cursor: pointer;
    @include typo-rwd(dm-sans-baseline-bold);
    padding: 1rem 1.25rem;
    border: 0.0625rem solid $gray-300;
    background: $white;
    color: $gray-900;
    width: auto;
    text-align: center;
    border-radius: 0.25rem 0 0 0.25rem;
    transition: all $color-change ease;

    &:hover {
      background: $gray-300;
    }

    span {
      margin-right: 0.1875rem;
    }

    + .tabItem {
      margin-left: -0.0625rem;
      border-radius: 0 0.25rem 0.25rem 0;
    }

    &.is-active {
      background: $dark-blue-900;
      color: $white;
      border-color: $dark-blue-900;
    }
  }

  @include mq(md) {
    padding-bottom: 2.5rem;
    &-wrapper {
      padding: 2.5rem 1rem;
    }

    .tabItem {
      min-width: 260px;
    }
  }

  @include mq(lg) {
    &-wrapper {
      padding: 3.25rem 1rem 4rem;
    }
  }
}

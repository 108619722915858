// Radio

.radio {
  $self: &;
  position: relative;
  margin-bottom: 16px;
  display: block;

  .errorText {
    padding-left: 32px;
  }

  &.is-disabled {
    pointer-events: none;

    #{$self}-labelText {
      color: $gray-300;
    }

    #{$self}-input {
      &::before {
        border-color: $gray-200;
      }

      &:checked {
        &::after {
          background: $gray-300;
        }
      }
    }
  }

  &-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    outline: none;
    display: block;
    position: absolute;
    top: 4px;
    left: 2px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: -2px;
      width: 24px;
      height: 24px;
      background-color: $white;
      border: 2px solid $gray-400;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      transition: background-color $color-change, border $color-change;
      z-index: 2;
    }

    &:checked {
      &::before {
        transition: all $general-change;
        border: 2px solid $dark-blue-900;
      }
      &::after {
        transition: all $general-change;
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: -1px;
        left: 2px;
        border-radius: 50%;
        background: $dark-blue-900;
        z-index: 2;
      }
    }
  }

  &-text {
    padding-left: 32px;
  }

  &-label {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    &:hover {
      cursor: pointer;
    }
  }
}

.radioGroup {
  .radio {
    margin-bottom: 20px;
  }
}

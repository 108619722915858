// Detail Header

.detailHeader {
  padding: 32px 16px;

  @include mq(md) {
    padding: 2.5rem 1rem;
  }

  @include mq(lg) {
    padding: 32px 0;
    display: flex;
    align-items: center;

    .detailPlayer {
      flex: 0 0 14.625rem;
    }

    .detailGames {
      flex: 1 1 auto;
      padding-left: 6.25rem;
    }
  }
}

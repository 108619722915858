// Brackets

.bracketCard {
  padding: 0.4375rem 0.5rem;
  border: 0.0625rem solid $gray-200;
  background: $white;
  position: relative;

  + .bracketCard {
    margin-top: -1px;
  }

  &:after {
    content: '';
    width: 0.25rem;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    background: $gray-50;
  }

  .player-userName {
    color: $gray-600;
  }

  .player-console {
    color: $gray-400;
    .icon {
      color: $gray-400;
    }
  }

  &--winner {
    color: $gray-900;
    &:after {
      background: $green-400;
    }
  }

  &--loser {
    color: $gray-400;
  }

  &--pending {
    color: $gray-900;
  }

  @include mq(md) {
    max-width: 19.0625rem;
  }
}

.bracketMatch {
  min-width: 0;
  .bracketCard {
    $self: &;
    position: relative;
    &:last-child {
      margin-top: -0.0625rem;
    }
  }
}

// LINKS

.link {
  $self: &;
  display: inline-block;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.2px;
  font-weight: bold;

  &.is-disabled {
    pointer-events: none;
  }

  &--primary {
    color: $blue-200;
  }

  &--secondary {
    color: $white;
  }

  &:not(.is-light) {
    &:hover {
      color: $blue-400;
    }

    &.is-disabled {
      color: $dark-blue-500;
    }
  }

  // Link light

  &.is-light {
    font-size: 12px;
    line-height: 1.33;

    &.link--primary {
      color: $blue-800;
      &:hover {
        color: $blue-400;
      }
    }

    &.link--secondary {
      color: $gray-600;
      text-decoration: underline;
      &:hover {
        color: $blue-600;
      }
    }

    &.is-disabled {
      color: $gray-300;
    }
  }
}

// Summary tiles

.summaryTiles {
  padding: 3.125rem 1rem;

  .summaryTile {
    margin-right: -0.0625rem;
    margin-top: -0.0625rem;
  }

  @include mq(md) {
    padding: 3.125rem 1.5rem;

    .wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }
    .summaryTile {
      min-height: 9.75rem;
    }
  }
  @include mq(lg) {
    padding: 4.75rem 1.25rem;
  }
}

// Summary Tile

.summaryTile {
  border: 0.0625rem solid $gray-200;
  padding: 1.75rem;
  display: flex;
  align-items: center;
  .image {
    margin-right: 0.75rem;
  }

  .title {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    margin-bottom: 0.125rem;
  }

  .description {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
  }

  @include mq(md) {
    flex-direction: column;
    align-items: flex-start;

    .image {
      margin-bottom: 0.75rem;
    }
  }
}

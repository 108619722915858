// Champions

.champions {
  background: $dark-blue-900;
  color: $white;
  padding: 2rem 0;
  position: relative;
  overflow: hidden;

  .left-print,
  .right-print {
    display: none;
  }

  &-row {
    text-align: center;
    max-width: 53.5625rem;
    margin: 0 auto;
  }

  .portrait {
    display: inline-block;
    z-index: 2;
    position: relative;
    &.is-big {
      margin-bottom: 2.5rem;
    }
  }

  @include mq(md) {
    .left-print,
    .right-print {
      display: block;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .left-print {
      right: calc(100% - 7.5rem);
      top: 18%;
    }

    .right-print {
      left: calc(100% - 10rem);
      bottom: 15%;
    }
  }

  @include mq(lg) {
    padding: 3rem 0;

    .left-print,
    .right-print {
      width: 80%;
    }

    .left-print {
      right: calc(100% - 16.25rem);
    }

    .right-print {
      left: calc(100% - 17.5rem);
    }
  }

  @include mq(xl) {
    padding: 4.25rem 0;
    &-row {
      text-align: left;

      &:first-child {
        text-align: right;
      }
    }

    .left-print,
    .right-print {
      width: 60%;
    }

    .left-print {
      right: 62%;
    }

    .right-print {
      left: 65%;
      bottom: 10%;
    }
  }

  @include mq(xxl) {
    .left-print,
    .right-print {
      width: 73.0625rem;
    }

    .left-print {
      right: 54%;
    }

    .right-print {
      left: 55%;
    }
  }
}

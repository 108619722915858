// Chip

.chip {
  $self: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 26px;
  border: 1px solid transparent;
  border-radius: 20px;

  &.is-small {
    height: 19px;
    #{$self}-text {
      @include typo-rwd(dm-sans-step-down-3-regular);
      padding-top: 2px;
    }
  }

  &-text {
    display: block;
    color: $gray-900;
    @include typo-rwd(dm-sans-step-down-1-regular);
  }

  &--success {
    background: lighten($green-800, 56%);
    border-color: $green-800;
  }

  &--fail {
    background: lighten($red-600, 46%);
    border-color: $red-600;
  }
}

// Modal

.modal {
  $self: &;
  background: $white;
  width: 100%;
  height: 100%;
  padding: 4.5rem 1.25rem 2.5rem;
  overflow-y: auto;
  animation: fade-in $general-change;

  &.is-dark {
    background: $dark-blue-900;
    color: $white;
  }

  &-header {
    padding-bottom: 2.5rem;
  }

  &-title {
    @include typo-rwd(stapel-step-up-2);
    text-align: center;
  }

  &-subtitle {
    @include typo-rwd(dm-sans-step-down-1-regular);
    text-align: center;
    margin-top: 0.75rem;
    color: $gray-600;

    p {
      margin-top: 1.25rem;
    }

    + .button {
      margin-top: 1.5rem;
    }
  }

  &-body {
    #{$self}-caption {
      text-align: left;
      padding: 0;
    }

    .helperText {
      margin: 24px 0;
    }
  }

  &-actions {
    padding-top: 2.75rem;
  }

  .errorCard {
    margin-top: 1.5rem;
  }

  .form-field {
    margin-bottom: 0.625rem;
    + .form-field {
      margin-top: 1.875rem;
    }
  }

  .select {
    margin-bottom: 0.75rem;
  }

  .button {
    width: 100%;
    + .button {
      margin-top: 1rem;
    }

    + .modal-subtitle {
      margin-top: 1.5rem;
    }
  }

  .button-icon {
    &:hover {
      .icon {
        color: $blue-400;
      }
    }
  }

  .bulletDivider {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    background: $gray-600;
    display: block;
    margin: -0.9375rem auto 1.25rem;
  }

  &-caption {
    padding-top: 1.25rem;
    @include typo-rwd(dm-sans-step-down-2-regular);
    text-align: center;
    color: $gray-600;

    .link {
      color: $blue-800;
      @include typo-rwd(dm-sans-step-down-2-bold);
      text-transform: lowercase;
    }
  }

  &-confirmation {
    padding: 2.5rem 0;
    text-align: center;

    .rounded-image {
      margin: 1.5rem 0;
    }

    #{$self}-text {
      margin-bottom: 3rem;
      @include typo-rwd(dm-sans-baseline-regular);
    }
  }

  .button-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  @include mq($until: md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &-caption {
      margin-top: auto;
    }
  }

  @include mq(md) {
    padding: 2.75rem 2.5rem 1.25rem;
    max-height: 90%;
    max-width: 34.125rem;
    height: auto;
    border-radius: 0.25rem;
    box-shadow: $elevation2;
    &-title {
      font-size: 28px;
      line-height: 36px;
    }

    &-overlay {
      z-index: 999;
      position: fixed;
      padding: 1.25rem;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      background: rgba($dark-blue-900, 0.7);
      animation: fade-in $general-change;

      .modal {
        @include centered(absolute);
      }
    }
  }
}

// Winners

.winners {
  max-width: 60rem;
  margin: 0 auto;

  .portrait {
    margin: 0 0 1.25rem;
  }

  @include mq(sm) {
    .portrait {
      margin: 0 1.25rem 1.25rem;
    }
  }

  @include mq(lg) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .portrait {
      margin: 0 0.5rem 0;
    }
  }
}

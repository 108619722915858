// Participants TGA

.participants-tga {
  background: $dark-blue-900;
  color: $white;
  padding: 0 1rem 4.5rem;

  .stepUp4-title {
    text-align: center;
    margin-bottom: 1.25rem;
  }

  .portraitCard {
    color: $white;
    margin-bottom: 0;
    animation: fade-in $general-change;
  }

  .portraitCard-name,
  .portraitCard-nickname {
    color: $white;
  }

  .portraitCard-name {
    opacity: 0.87;
    margin-top: 0.125rem;
    @include typo-rwd(dm-sans-step-down-1-regular);
  }

  .socialItem {
    .bg {
      fill: $dark-blue-600;
    }
    .shape {
      fill: $white;
    }

    &:hover {
      .bg {
        fill: $white;
      }

      .shape {
        fill: $dark-blue-600;
      }
    }
  }

  .participants {
    max-width: 47.5rem;
    margin: 0 auto;
    padding: 0;
  }

  .participants-actions {
    text-align: center;
    padding-top: 2.5rem;
  }

  @include mq(md) {
    .participants {
      display: flex;
      flex-wrap: wrap;
    }

    .stepUp4-title {
      margin-bottom: 3.125rem;
    }

    .participants-actions {
      padding-top: 4.3125rem;
    }

    .portraitCard {
      flex: 1 1 50%;
      &:nth-child(odd) {
        padding-right: 0.625rem;
      }
      &:nth-child(even) {
        padding-left: 0.625rem;
      }
    }
  }
  @include mq(xl) {
    .participants {
      max-width: 1000px;
    }
    .portraitCard {
      flex-basis: 33.3%;
    }
  }
}

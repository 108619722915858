// Breadcrumbs

.breadcrumbs {
  color: $white;
  padding: 0.5rem;

  &--default {
    background: $dark-blue-600;
  }

  &--dark {
    background: $dark-blue-900;
  }

  &--light {
    background: $gray-50;
    color: $gray-900;
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  .breadcrumb-item {
    @include typo-rwd(dm-sans-step-down-2-regular);
    span,
    a {
      display: inline-block;
    }
    span {
      position: relative;
      top: -1px;
    }

    &:not(:last-child) {
      &:after {
        content: '>';
        display: inline-block;
        vertical-align: middle;
        padding: 0 0.25rem;
      }
    }
    &:last-child {
      width: 100%;
      overflow: hidden;
    }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      min-width: 0;
      overflow: hidden;
    }
  }

  a {
    font-weight: $bold;
  }
}

// Edit profile

.editProfile {
  padding-top: 48px;
  padding-bottom: 48px;

  &-block {
    padding-top: 48px;
    .stepDown1-title {
      margin-bottom: 24px;
    }
  }

  .errorCard {
    margin: 0 0 24px 0;
  }

  .submitBtn {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
  }
}

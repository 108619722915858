.radioButtonGroup {
  $self: &;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;

  &.is-disabled {
    .radioButton {
      pointer-events: none;
      color: $gray-300;
    }

    .radioButton-labelText {
      color: $gray-300;
    }

    .radioButton-label::before {
      border-color: $gray-200;
    }

    #{$self}-label {
      color: $gray-300;
    }
    .radioButton-input:checked + .radioButton-label::before {
      background: $gray-100;
    }
    .radioButton-input:checked + .radioButton-label .radioButton-labelText {
      color: $gray-300;
    }
  }

  &-label {
    @include typo-rwd(dm-sans-step-down-1-regular);
    display: block;
    position: absolute;
    top: -1.875rem;
    left: 0;
    color: $gray-600;
  }

  .radioButton {
    max-width: 50%;

    & + .radioButton .radioButton-label::before {
      border-left: 0;
    }
  }

  .radioButton:first-child .radioButton-label::before {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .radioButton:last-child .radioButton-label::before {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

// Loader

.loader {
  display: inline-block;
  &-overlay {
    background: rgba($dark-blue-900, 0.7);
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      width: 100px;
      height: 100px;
    }
  }
}

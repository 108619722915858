.wysiwyg {
  $self: &;
  display: block;
  position: relative;
  padding: 3rem 1.625rem 4rem 1.625rem;
  text-align: center;
  box-sizing: border-box;

  &-wrapper {
    > span {
      display: block;
      margin-bottom: 20px;
    }
  }

  p,
  li {
    @include typo-rwd(dm-sans-step-up-1-regular);
    color: $gray-600;
    margin-bottom: 20px;
  }

  ul {
    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  ol {
    li {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }

  .wysiwyg-list-latin {
    padding-left: 40px;
    li {
      list-style-type: lower-latin;
      list-style-position: outside;
      &:before {
        display: none;
      }
    }
  }

  a {
    color: $blue-600;
    &:hover {
      text-decoration: underline;
    }
  }

  blockquote,
  code {
    margin-bottom: 20px;
  }

  blockquote {
    border-left: 5px solid $gray-100;
    padding: 10px 6px;
    font-style: italic;
  }

  code {
    background: $gray-100;
    padding: 4px 8px;
    line-height: 1;
    display: inline-block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-900;
    margin-bottom: 12px;
    padding-top: 8px;
  }

  h1 {
    @include typo-rwd(stapel-step-up-1);
  }

  h2 {
    @include typo-rwd(dm-sans-step-up-1-bold);
  }

  h3 {
    @include typo-rwd(dm-sans-baseline-bold);
  }

  .wrapper {
    display: block;
    position: relative;
    margin: 0 auto;
    text-align: left;
    max-width: $layout-maxWidth;
  }

  &-headline {
    @include typo-rwd(dm-sans-step-up-1-bold);
    display: block;
    position: relative;
    color: $gray-900;

    & + * {
      margin-top: 2rem;
    }

    & + .wysiwyg-paragraph {
      margin-top: 1rem;
    }

    &.big {
      @include typo-rwd(dm-sans-step-up-3-bold);
    }
  }

  &-paragraph,
  &-list {
    @include typo-rwd(dm-sans-baseline-regular);
    display: block;
    position: relative;
    color: $gray-600;

    span {
      display: block;
      position: relative;
    }

    & + * {
      margin-top: 2rem;
    }

    & + .wysiwyg-paragraph,
    & + .wysiwyg-list {
      margin-top: 0.5rem;
    }
  }

  .table-wrapper {
    margin-bottom: 1.875rem;
  }

  .checkbox {
    margin-top: 1.25rem;
    .checkbox-labelText {
      @include typo-rwd(dm-sans-baseline-regular);
    }
    svg {
      top: 2px;
    }

    &-input {
      &:before {
        top: 4px;
      }
    }

    .link {
      @include typo-rwd(dm-sans-baseline-regular);
      display: inline-block;
    }
  }

  .indent {
    padding-left: 2.5rem;
  }

  .indent-sm {
    padding-left: 1.5rem;
  }

  &-list li {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    box-sizing: border-box;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.625rem;
      left: 0.5rem;
      width: 0.25rem;
      height: 0.25rem;
      background-color: $gray-600;
      border-radius: 0.5rem;
    }

    & + li {
      margin-top: 0.5rem;
    }
  }

  .bold {
    font-weight: bold;
  }

  .button {
    display: flex;
    margin: 2.5rem auto;
  }

  @include mq(lg) {
    padding: 3.75rem 1.625rem 6.25rem 1.625rem;

    &-headline {
      @include typo-rwd(dm-sans-step-up-2-bold);

      & + * {
        margin-top: 2.5rem;
      }

      & + .wysiwyg-paragraph {
        margin-top: 1.5rem;
      }
    }

    &-paragraph,
    &-list {
      @include typo-rwd(dm-sans-step-up-1-regular);

      & + * {
        margin-top: 2.5rem;
      }

      & + .wysiwyg-paragraph,
      & + .wysiwyg-list {
        margin-top: 1rem;
      }
    }

    &-list li + li {
      margin-top: 1rem;
    }
  }
}

.wysiwyg {
  .optanon-toggle-display {
    background: $red-600;
    color: $white;
    font-size: 1rem;
    letter-spacing: 0.0125rem;
    padding: 10px 20px;
    display: inline-block;
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    font-weight: $bold;
    min-height: 3.5rem;
    transition: all $color-change ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    &:hover {
      background: $red-800;
    }
  }
}

.cookie-settings-button {
  outline: none !important;
}

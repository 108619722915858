// Switch

.switch {
  $self: &;
  &-label {
    display: flex;
    align-items: center;
  }

  &-icon {
    width: 32px;
    height: 32px;
    color: $gray-200;
    position: absolute;
    top: 0;
    left: 0;
    transition: $general-change;
    path {
      fill: currentColor;
    }
  }

  &-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 60px;
    height: 32px;
    background: $gray-100;
    border-radius: 70px;
    margin-right: 8px;
    outline: none;
    transition: $general-change;
    cursor: pointer;
    border: 1px solid $gray-100;

    &:checked {
      background: $green-400;
      border-color: $green-400;
      + #{$self}-icon {
        color: #000;
        transform: translateX(28px) rotate(90deg);
      }
    }
  }

  &-item {
    position: relative;
    cursor: pointer;
  }

  &-text {
    margin-bottom: 6px;
  }

  &-textLabel {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-900;
  }

  &.is-disabled {
    #{$self}-input {
      background: $gray-100;
      cursor: initial;
    }

    #{$self}-icon {
      color: $gray-200;
    }

    #{$self}-textLabel {
      color: $gray-400;
    }
  }
}

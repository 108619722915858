.select {
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 0.5rem;

  &.is-disabled {
    pointer-events: none;

    .select-select {
      color: $gray-300;
      border: 1px solid $gray-300;
    }

    .select-label {
      color: $gray-300;
    }
  }

  &-select {
    @include typo-rwd(dm-sans-baseline-regular);
    display: block;
    position: relative;
    margin: 0;
    padding: 1rem 1rem 1.125rem 1rem;
    width: 100%;
    height: 3.5rem;
    color: $gray-900;
    background-color: $white;
    border: 1px solid $gray-300;
    border-radius: 0.25rem;
    cursor: pointer;
    outline: none;
    transition: border $color-change;
    -webkit-appearance: none;
    appearance: none;

    // &:focus + .select-arrow {
    //   transform: rotate(-180deg);
    // }
  }

  &-arrow {
    display: block;
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    transition: transform $small-item-change;
  }

  &-label {
    @include typo-rwd(dm-sans-step-down-2-regular);
    display: inline-block;
    position: absolute;
    padding: 0 0.25rem;
    top: 0;
    left: 0.75rem;
    color: $gray-600;
    background-color: $white;
    box-sizing: border-box;
    pointer-events: none;
    transform: translateY(-50%);
  }

  @include mq(md) {
    padding-bottom: 1.25rem;
  }
}

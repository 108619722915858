// Participants

.participants {
  padding: 1.625rem 1rem;

  &-filters {
    padding-bottom: 1.4375rem;
  }

  &-results {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    margin-bottom: 0.75rem;
  }

  .select {
    padding-bottom: 1.5rem;
  }

  .pagination {
    justify-content: center;
    padding: 2.5rem 0 1.25rem;
  }

  @include mq(md) {
    padding: 3.75rem 1rem;
    &-filters {
      padding-bottom: 2.5rem;
    }
  }
}

// SHARE BAR
.shareBar {
  padding: 1.25rem 1.75rem;
  box-shadow: 0 -0.0625rem 0 0 $gray-200 inset;

  .wrapper {
    @include mq(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    @include mq(lg) {
      align-items: center;
    }
  }

  &-share {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1.25rem;

    @include mq(md) {
      margin-bottom: 0;
    }

    .label {
      margin-right: 1.25rem;
      @include typo-rwd(dm-sans-step-down-1-regular);
      margin-top: -0.25rem;
    }

    .socialItem {
      &:not(:last-child) {
        margin-right: 1rem;
      }

      .bg {
        fill: $gray-100;
        transition: fill $quick-change;
      }

      .shape {
        fill: $gray-900;
        transition: fill $quick-change;
      }

      &:hover {
        .bg {
          fill: $gray-900;
        }

        .shape {
          fill: $gray-100;
        }
      }
    }
  }

  &-actions {
    @include mq(md) {
      display: flex;
      flex-direction: column;
    }
    @include mq(lg) {
      flex-direction: row;
    }

    .button {
      width: 100%;

      @include mq(md) {
        width: auto;
      }

      &:not(:last-child) {
        margin-bottom: 1.25rem;

        @include mq(lg) {
          margin-right: 1.25rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

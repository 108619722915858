.formBase {
  display: block;
  position: relative;
  margin: 0.625rem auto 0;
  padding: 0 1rem 3.5rem 1rem;

  .top-space {
    margin-top: 0.625rem;
  }

  &-row {
    display: block;

    &:first-child {
      padding: 1rem 0 0 0;

      .formBase-cell {
        margin: 0;
      }

    }
    &:nth-child(2) {
      margin-top: 0;
      padding: 0 0 1rem;
    }

    & + .formBase-row {
      margin-top: 1rem;
    }
  }

  .helperText {
    + .checkbox {
      margin-top: 10px;
    }
  }
  &-cell {
    display: block;
    position: relative;
    margin: 2rem;
  }

  .button {
    min-width: 100%;
    text-align: center;

    .button-text {
      margin: 0 auto;
    }
  }

  .select {
    margin-top: 1.5rem;
  }

  .radioButtonGroup {
    margin-top: 3rem;
  }

  &-controls {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2rem;
  }

  .errorCard {
    margin-top: 1.5rem;
  }

  @include mq($until: md) {
    &-row {
      margin: 0;

      &:nth-child(2) {
        padding-bottom: 1.25rem;
      }

      & + .formBase-row {
        margin-top: 1.75rem;
      }
    }

    .radioButtonGroup {
      margin-bottom: 1rem;
    }

    &-cell {
      margin: 0;
    }

    .helperText {
      margin: 0.5rem 0;
    }

    .helperCard {
      margin-top: 0.625rem;
    }

    &-controls {
      .button:first-child {
        margin-top: 0.5rem;
      }
    }
  }

  @include mq($until: lg) {
    &-controls {
      .button + .button {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
      }
    }
  }

  @include mq(md) {
    padding: 0 1rem 6.25rem 1rem;

    &-row {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    &-row {
      &:first-child {
        padding: 1.5rem 0 0 0;
      }
      &:nth-child(2) {
        padding-bottom: 2rem;
      }

      & + .formBase-row {
        margin-top: 2rem;
      }
    }

    &-cell {
      display: block;
      position: relative;
      margin: 0;
      min-width: calc(50% - 10px);
      max-width: calc(50% - 10px);
    }

    .button {
      min-width: 0;
    }

    .select,
    .radioButtonGroup {
      margin-top: 10px;
    }

    &-controls {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      margin-top: 3rem;
      max-width: calc(50% - 0.625rem);

      .button + .button {
        margin-left: 0.625rem;
      }
    }
  }

  @include mq(lg) {
    &-cell {
      min-width: calc(50% - 1.25rem);
      max-width: calc(50% - 1.25rem);
    }

    &-controls {
      max-width: calc(50% - 1.25rem);
      .button + .button {
        margin-left: 1.5rem;
      }
    }
  }
}

// Personal info form

.personalInfo {
  &-form {
    .form-field {
      margin-bottom: 24px;
    }
  }
}

// Hero

.hero {
  $self: &;
  background: $dark-blue-900;
  color: $white;
  position: relative;
  padding: 113px 16px 0;
  overflow: hidden;

  &-ribbon {
    z-index: 2;
    background: $white;
    color: $dark-blue-900;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
    position: absolute;
    right: 0;
    transform: rotate(28deg);
    transform-origin: 28% 100%;
    width: 200px;

    &:before,
    &:after {
      content: '';
      width: 120px;
      height: 56px;
      background: $white;

      position: absolute;
      z-index: 0;
    }

    &:before {
      top: 0;
      left: -60px;
      transform: skew(-62deg);
    }

    &:after {
      bottom: 0;
      transform: skew(60deg);
      left: 120px;
    }
  }

  &-ribbonText {
    max-width: 130px;
    margin: 0 auto;
    @include typo-rwd(stapel-step-down-1-caps-bold);
    z-index: 2;
    position: relative;
  }

  .print {
    @include full-space(3);
    object-fit: cover;
    object-position: 40% 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  &-image {
    flex: 0 0 50%;
    z-index: 3;
    position: relative;
    top: 0.3125rem;
    text-align: center;
    img {
      width: 100%;
      max-width: none;
    }
  }

  &-image-compose {
    @include full-space;

    &::before {
      @include full-space(3);
      content: '';
      background-image: linear-gradient(
        135deg,
        rgba(35, 7, 70, 0.5) 0%,
        rgb(35, 7, 70) 100%
      );
    }
  }

  &-image-full {
    @include full-space(2);
    filter: url(#svgMonotoneGradientBlur);

    > img {
      object-fit: cover;
      object-position: 40% 0;
    }
  }

  &-info {
    z-index: 3;
    position: relative;
    max-width: 28.75rem;
    padding-bottom: 24px;
    align-self: center;
  }

  &-helperText {
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
  }

  .stepUp6-title {
    margin-bottom: 1rem;
  }

  .baseline-text {
    margin-top: 1rem;
  }

  .button {
    margin-top: 2rem;
  }

  // Only mobile
  @include mq($until: md) {
    padding: 6rem 1rem 0;
    overflow: hidden;
    max-height: 43.125rem;

    .wrapper {
      flex-direction: column;
    }

    &-info {
      order: -1;

      .button {
        width: 100%;
        margin: 0;
      }
    }
    .image {
      width: 80%;
    }

    .stepUp3-text {
      margin-bottom: 1.75rem;
    }
  }

  @media screen and (min-width: 37.5rem) and (max-width: 43.75rem) {
    &-logo {
      width: 4.375rem;
    }

    &.hero-countdown {
      .wrapper {
        align-items: flex-end;
      }

      #{$self}-image {
        padding-bottom: 3rem;
      }
    }
  }

  @include mq(md) {
    &-countdown {
      #{$self}-info {
        padding-bottom: 4.375rem;
      }
    }
    .wrapper {
      width: 100%;
      align-items: flex-end;
    }
    .hero-info--half {
      flex: 0 0 50%;
      margin-left: auto;
    }
  }

  @include mq(lg) {
    display: flex;
    align-items: flex-end;

    &-image {
      padding-right: 1.25rem;
      img {
        margin-left: -4.3125rem;
        width: 34.875rem;
      }
    }
  }

  @include mq(xl) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include mq(xxl) {
    min-height: 46.25rem;

    .print {
      object-position: 0 80%;
    }
  }
}

// Hero Simple / tga and gt

.heroSimple {
  $self: &;
  text-align: center;
  padding: 100px 0 32px;
  min-height: 216px;
  align-items: center;

  .wrapper {
    display: block;
    z-index: 2;
    position: relative;
  }

  &-logo,
  &-text {
    display: block;
    z-index: 2;
  }

  &-logo {
    width: 80%;
    max-width: 434px;
    margin: 0 auto 24px;
    shape-rendering: geometricPrecision;
    padding-top: 48px;
  }

  &.gt {
    #{$self}-logo {
      max-width: 527px;
    }
  }

  &-text {
    @include typo-rwd(dm-sans-step-up-2-regular);
  }

  @include mq(md) {
    padding: 100px 0 60px;

    &-logo {
      width: 100%;
    }
  }

  @include mq(lg) {
    padding: 154px 0 104px;
    min-height: 540px;
  }
}

.hero--competition {
  @include mq(lg) {
    min-height: 46.25rem;
  }

  @include mq(md) {
    align-items: center;
    .wrapper {
      align-items: center;
    }
  }
  @include mq(xxl) {
    .hero-info--half {
      margin-left: calc(50% + 1.25rem);
    }
  }
}

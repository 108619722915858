// Errors

.errorBanner {
  background: $dark-blue-900;
  color: $white;
  min-height: 100vh;
  position: relative;
  padding: 24px;

  .print {
    position: absolute;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }

  .wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
  }

  .stepUp6-title {
    margin-bottom: 4px;
    max-width: 717px;
  }

  .stepUp3-text {
    margin-bottom: 20px;
    max-width: 717px;
  }

  .button {
    margin-top: 10px;
  }

  .error-subtext {
    @include typo-rwd(dm-sans-baseline-regular);
    opacity: 0.8;
    max-width: 558px;
    margin-bottom: 32px;
  }

  .link {
    display: block;
    padding: 0 0 12px 0;
  }

  @include mq($until: lg) {
    padding-top: 160px;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 4, 41, 1) 0%,
        rgba(0, 4, 41, 0) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  @include mq($until: 600px, $media-feature: height) {
    padding-top: 160px;
  }

  @include mq(lg) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

// Static banners

.staticBanners {
  background: $dark-blue-900;
  color: $white;
  padding: 56px 1rem 0;
  overflow: hidden;

  &-title {
    margin-bottom: 1rem;
    @include typo-rwd(stapel-step-up-4);
  }

  &-subtitle {
    margin-bottom: 2.9375rem;
    opacity: 0.87;
    @include typo-rwd(dm-sans-step-up-1-regular);
  }

  @include mq($from: 660px) {
    &-title,
    &-subtitle {
      text-align: center;
      max-width: 47.5rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }
  }

  @include mq(lg) {
    &-title {
      @include typo-rwd(stapel-step-up-5);
    }

    &-subtitle {
      @include typo-rwd(dm-sans-step-up-2-regular);
    }
  }
}

// Static Banner

.staticBanner {
  $self: &;
  padding: 20px 0;
  min-height: 37.5rem;

  &-wrapper {
    max-width: 75rem;
    margin: 0 auto;
    position: relative;
  }

  &-title {
    @include typo-rwd(stapel-step-up-4);
    margin-bottom: 0.75rem;
  }

  &-text {
    @include typo-rwd(dm-sans-step-up-2-regular);
    opacity: 0.8;
    margin-bottom: 2.5rem;
  }

  &.banner-ecopa {
    #{$self}-image {
      img {
        width: 17.625rem;
      }
    }
  }

  @include mq($until: md) {
    &-title {
      @include typo-rwd(stapel-step-up-2);
    }

    &-text {
      @include typo-rwd(dm-sans-baseline-regular);
    }

    .button {
      width: 100%;
    }
  }

  @include mq($until: lg) {
    max-width: 600px;
    margin: 0 auto;

    &-info {
      margin-bottom: 60px;
    }

    &-title {
      @include typo-rwd(stapel-step-up-3);
    }

    &-image {
      text-align: center;
    }
  }

  @include mq(lg) {
    padding: 3.75rem 0;
    &-info {
      width: 50%;
      max-width: calc(29.1875rem + 3.75rem);
      padding: 0 3.75rem 0 1rem;
    }

    &-image {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      right: 0;
      width: 50%;
      height: 100%;
      margin-left: auto;
      img {
        display: block;
        max-width: none;
      }
    }

    &.banner-ecopa {
      #{$self}-info {
        width: 60%;
      }

      #{$self}-image {
        img {
          margin-left: auto;
          margin-right: 2.5rem;
        }
      }
    }

    &.banner-tga,
    &.banner-gt {
      #{$self}-image {
        img {
          position: absolute;
          top: 0;
          width: 43.75rem;
        }
      }
    }

    &.banner-gt {
      #{$self}-image {
        right: 0;
        .image {
          margin-top: 80px;
        }
      }
    }

    &.banner-tga {
      #{$self}-info {
        margin-left: auto;
        padding: 0 1rem 0 3.75rem;
      }

      #{$self}-image {
        right: 50%;
        left: 0;
        img {
          left: -35%;
        }
      }
    }
  }

  @include mq(xl) {
    &.banner-ecopa {
      #{$self}-image {
        img {
          margin-right: 4.75rem;
        }
      }
    }

    &.banner-tga {
      #{$self}-image {
        img {
          left: -10%;
        }
      }
    }
  }
}

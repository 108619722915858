// Map legend

body {
  counter-reset: section;
}

.mapLegend {
  $self: &;
  width: 26.5rem;
  box-shadow: $elevation2;
  border-radius: 0.25rem;
  padding: 1rem;
  background: $white;
  color: $dark-blue-900;
  &-row {
    display: flex;
    margin-bottom: 0.75rem;
    &.is-header {
      #{$self}-cell {
        @include typo-rwd(dm-sans-step-down-2-bold-caps);
        text-transform: uppercase;
      }
    }
    &:not(.is-header) {
      &:before {
        @include typo-rwd(dm-sans-step-down-1-bold);
        counter-increment: section;
        content: counter(section) '. ';
      }
    }
  }

  .inscribed {
    color: $gray-400;
  }

  .title {
    flex: 1 1 auto;
    padding-left: 0.125rem;
    @include typo-rwd(dm-sans-step-down-1-bold);
  }

  .remain {
    flex: 0 0 6.25rem;
  }

  .inscribed {
    flex: 0 0 4.375rem;
  }
}

// Player Top section

.playerTop {
  $self: &;
  &-section {
    text-align: center;
    background: url('../../../assets/images/top-5-min.png') no-repeat;
    background-size: cover;
    color: $white;
    padding: 2rem 0 2.5rem;

    #{$self}-list {
      padding: 2.25rem 0 2.5rem;
      max-width: 35.25rem;
      margin: 0 auto;
    }
  }

  &-wrapper {
    max-width: 47.5rem;
    margin: 0 auto;
    padding: 0 1rem;
  }

  &-title {
    @include typo-rwd(stapel-step-up-3);
    margin-bottom: 0.5rem;
  }

  &-subtitle {
    @include typo-rwd(dm-sans-baseline-regular);
  }

  @include mq($until: sm) {
    &-section {
      background-position: 46% 50%;
    }

    &-actions {
      .button {
        width: 100%;
      }
    }
  }

  @include mq(lg) {
    &-section {
      padding: 2.5rem 0 2.75rem;
    }
  }

  @include mq(xl) {
    &-section {
      padding: 3.25rem 0 4.5rem;
      background-position: 46% 30%;
    }

    &-title {
      @include typo-rwd(stapel-step-up-4);
    }

    &-subtitle {
      @include typo-rwd(dm-sans-step-up-1-regular);
    }
  }
  @include mq(xxl) {
    &-wrapper {
      max-width: 54.0625rem;
    }

    &-section {
      #{$self}-list {
        max-width: 54.0625rem;
      }
    }
  }
}

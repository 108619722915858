// MAP

.mapWrapper {
  width: 100%;

  .region {
    fill: $purple-50;
  }

  .highlight {
    fill: $purple-100;
  }
}

.mapSection {
  position: relative;
  background: $gray-50;

  .stepUp4-title {
    text-align: center;
    position: absolute;
    top: 0.75rem;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .mapWrapper {
    position: relative;
    overflow: hidden;
    svg {
      width: 100%;
      height: auto;
    }
  }

  .mapLegend {
    margin: 0 auto;
    width: 100%;
    max-width: 26.5rem;
  }

  .mapLegend-wrapper {
    padding: 0.25rem;
  }

  @include mq(md) {
    .mapLegend-wrapper {
      padding: 1.25rem 0;
    }
  }
  @include mq(lg) {
    background: $white;
    .mapLegend {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
      &-wrapper {
        padding: 0;
      }
    }
    .stepUp4-title {
      top: 1.75rem;
    }

    .mapWrapper {
      width: 100%;
      height: 47.875rem;
      position: relative;
      overflow: hidden;
      svg {
        @include centered(absolute);
        width: 120%;
        height: 100%;
        max-width: 123.75rem;
      }
    }
  }
  @include mq(xl) {
    .mapWrapper {
      height: 62.5rem;
    }
    .stepUp4-title {
      top: 2.8125rem;
    }
  }
}

.radioButton {
  display: block;
  position: relative;
  width: 100%;
  height: 3.5rem;
  max-width: 14.5625rem;
  overflow: hidden;

  &-input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: translate3d(-100%, -100%, 0);

    &:checked + .radioButton-label {
      &::before {
        background-color: $dark-blue-900;
        border: 1px solid $dark-blue-900;
      }

      .radioButton-labelText {
        color: $white;
      }
    }
  }

  &-label {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      &::before {
        background: $gray-300;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      border: 1px solid $gray-300;
      border-radius: 0.25rem 0 0 0.25rem;
      box-sizing: border-box;
      transition: background-color $color-change, border $color-change;
      z-index: 0;
    }
  }

  &-labelText {
    @include typo-rwd(dm-sans-baseline-bold);
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    color: $gray-900;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
    transition: color $color-change;
  }
}

// Snackbars

.snackBar {
  background: $purple-500;
  color: $white;
  padding: 0.75rem;
  position: relative;
  z-index: 12;

  .wrapper {
    display: flex;
    align-items: flex-start;
    > .iconWrapper {
      margin-right: 0.75rem;
    }
  }
  &-text {
    max-width: 45.25rem;
    @include typo-rwd(dm-sans-step-down-1-regular);
    margin-top: 0.1875rem;
    padding-right: 1.5rem;
  }

  .button-close {
    margin-left: auto;
    transition: color $color-change;
    &:hover {
      color: $purple-900;
    }
  }

  &-success {
    background: $green-800;
  }

  &.is-adaptative {
    width: auto;
    display: inline-flex;
    margin: 12px;
  }
}

.sticky {
  .snackBar {
    position: fixed;
    right: 0;
    left: 0;

    &.is-adaptative {
      left: auto;
    }
  }
}

// Brands

.brands {
  &-gt {
    text-align: center;
    padding: 0 16px;
    .brand {
      padding: 0 40px 20px;
    }
    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}

.checkbox {
  $self: &;
  display: block;
  position: relative;
  padding-top: 0.125rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  overflow: hidden;

  &.is-disabled {
    pointer-events: none;

    #{$self}-label,
    #{$self}-labelText,
    #{$self}-labelHelper {
      color: $gray-300;
    }

    #{$self}-input {
      &:before {
        border-color: $gray-200;
      }
      &:after {
        background: $gray-200;
      }

      &:checked {
        &:before {
          border-color: $gray-200;
          background: $gray-200;
        }
      }
    }
  }

  &.has-divider {
    margin-top: 0.5rem;
    padding-top: 1rem;
    border-top: 2px solid $gray-100;
  }

  &.has-error .checkbox-input::before {
    border: 2px solid $red-700;
  }

  &-item {
    padding-left: 1.875rem;
    position: relative;
  }

  &-input {
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    outline: none;
    border-radius: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      width: 1rem;
      height: 1rem;
      background-color: $white;
      border: 2px solid $gray-400;
      border-radius: 0.125rem;
      box-sizing: border-box;
      cursor: pointer;
      transition: background-color $color-change, border $color-change;
      z-index: 2;
    }

    &:checked::before {
      background-color: $dark-blue-900;
      border: 2px solid $dark-blue-900;
    }

    &:checked + {
      #{$self}-icon {
        opacity: 1;
      }
    }
  }

  &-icon {
    position: absolute;
    top: 0;
    left: -3px;
    width: 1.375rem;
    height: 1.3125rem;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
  }

  &-label {
    display: block;
    cursor: pointer;
  }

  &-labelText,
  &-labelHelper,
  .link,
  .errorText {
    @include typo-rwd(dm-sans-step-down-2-regular);
    display: block;
    position: relative;
    color: $gray-600;
  }

  &-labelHelper {
    color: $gray-400;
  }

  .link {
    margin-top: 0.25rem;
    text-decoration: underline;
  }

  .errorText {
    margin-top: 0.25rem;
    color: $red-700;
    padding-left: 0;
  }

  @supports (-webkit-touch-callout: none) {
    &-icon {
      top: -0.0625rem;
      left: 0;
    }
    &-input {
      top: 0.25rem;
      left: 0.25rem;
      width: 0;
      height: 0;

      &:before {
        top: -0.1875rem;
        left: -0.125rem;
      }
    }
  }
}

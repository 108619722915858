// Animations

// Transitions
$small-item-change: 0.3s ease;
$color-change: 0.3s;
$general-change: 0.3s ease-in-out;
$quick-change: 0.1s ease-in-out;

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes left-right {
  0% {
    transform-origin: 0 0;
    transform: scaleX(0);
  }

  100% {
    transform-origin: 0 0;
    transform: scaleX(100%);
  }
}

@keyframes bottom-appear {
  0% {
    transform-origin: 0 0;
    transform: translateY(100%);
  }

  100% {
    transform-origin: 0 0;
    transform: translateY(0);
  }
}

@keyframes skeleton {
  0% {
    background: $gray-50;
  }

  50% {
    background: $gray-200;
  }

  100% {
    background: $gray-50;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    height: 0px;
  }

  100% {
    opacity: 1;
    height: 52px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scale-up-down {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.083);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes success-to-disabled {
  0% {
    color: $white;
    background: $green-800;
    border-color: $green-800;
  }

  90% {
    color: $white;
    background: $green-800;
    border-color: $green-800;
  }

  100% {
    color: $gray-300;
    background: $gray-100;
    border-color: $gray-100;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

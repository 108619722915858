// Simple Navigation

.simpleNav {
  $height-xs: 3.5rem;
  $height-sm: 4.25rem;
  $height-md: 5rem;
  $height-xl: 6.25rem;

  background: $white;
  transition: all $color-change ease;
  height: $height-sm;
  padding: 0 8px;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .button {
    padding: 10px 0;
    min-height: 0;
    &:last-child {
      margin-left: auto;
    }
    &:hover {
      background: transparent;
      color: $blue-400;
    }
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
    flex: 1 1 auto;
    text-align: center;
    transition: all $color-change ease;
  }

  @include mq($until: md) {
    .button {
      font-size: 0.875rem;
    }
  }

  @include mq(md) {
    height: $height-md;

    &-title {
      @include typo-rwd(stapel-step-up-2);
    }
  }

  @include mq(lg) {
    height: $height-xl;

    &-title {
      font-family: $stapel;
      font-size: 1.75rem;
      line-height: 1.29;
    }
  }
}

.sticky {
  $height-xs: 3.5rem;
  $height-sm: 4.25rem;
  .simpleNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    height: $height-xs;
    box-shadow: $elevation1;
  }

  @include mq(md) {
    .simpleNav {
      height: $height-sm;
      &-title {
        @include typo-rwd(stapel-step-up-2);
      }
    }
  }
}

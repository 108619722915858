// All modules affected by header's height (if this changes in desktop
// review all of them

// Variables

$navHeight-sm: 56px;
$navHeight-lg: 80px;
$navHeight-xl: 140px;
$navSticky-sm: 56px;
$navSticky-lg: 80px;

// Main Nav

.mainNav {
  height: $navHeight-sm;
  @include mq(lg) {
    height: $navHeight-lg;
  }
  @include mq(xl) {
    height: $navHeight-xl;
  }
}

// Sticky navigation

.sticky {
  .mainNav {
    height: $navHeight-sm;
  }

  @include mq(lg) {
    .mainNav {
      height: $navHeight-lg;
    }
  }
}

// Empty Header

.emptyHeader {
  background: $dark-blue-900;
  width: 100%;

  &:not(.with-bar) {
    height: $navHeight-sm;

    @include mq(lg) {
      height: $navHeight-lg;
    }

    @include mq(xl) {
      height: $navHeight-xl;
    }
  }

  &.with-bar {
    height: calc(#{$navHeight-sm} + 2rem);

    @include mq(lg) {
      height: calc(#{$navHeight-lg} + 2.0625rem);
    }

    @include mq(xl) {
      height: calc(#{$navHeight-xl} + 2.0625rem);
    }
  }
}

// Snackbars

.sticky {
  .snackBar {
    top: $navHeight-sm;
  }
  @include mq(lg) {
    .snackBar {
      top: $navHeight-lg;
    }
  }
}

// Bar Slot

.mainLayout {
  .barSlot {
    top: $navHeight-sm;
  }

  @include mq(lg) {
    .barSlot {
      top: $navHeight-lg;
    }
  }

  @include mq(xl) {
    .barSlot {
      top: $navHeight-xl;
    }
  }
}

// Hero

.hero {
  &-ribbon {
    top: $navHeight-lg;
  }

  @include mq(lg) {
    &-ribbon {
      top: calc(#{$navHeight-lg} + 30px);
    }
  }

  @include mq(xl) {
    padding-top: calc(#{$navHeight-xl} + 9px);

    &-ribbon {
      top: calc(#{$navHeight-xl} + 26px);
    }
  }
}

// // Heading Banner

// .headingBanner {
//   padding-top: calc(#{$navHeight-lg} + 34px);

//   @include mq(xl) {
//     padding-top: calc(#{$navHeight-xl} + 34px);
//   }
// }

// Slider

.slider {
  padding: 0 1.25rem;
  .slick-next,
  .slick-prev {
    fill: $black;
    top: 3.125rem;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-arrow {
    transition: all $color-change ease;
    &:not(.slick-disabled) {
      &:hover {
        fill: $blue-400;
        cursor: pointer;
      }
    }
  }

  .slick-disabled {
    fill: $gray-300;
    cursor: initial;
  }
}

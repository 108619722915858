// Brick

.brick,
.brick-md,
.brick-sm {
  text-align: center;

  &.is-dark {
    background: $dark-blue-900;
    color: $white;
  }

  .brandLogo {
    margin: 1.25rem auto 0;
    display: block;
    transition: all $color-change ease;
    width: 10.625rem;
    &:hover {
      opacity: 0.6;
    }
  }
  @include mq(md) {
    .brandLogo {
      width: 22.875rem;
    }
  }
}

.brick-gray {
  background: $gray-50;
}

.brick {
  padding: 2.5rem 1rem;

  &.is-dark {
    + .brick.is-dark {
      padding-top: 0;
    }
  }

  + .brick:not(.is-dark) {
    padding-top: 1.5rem;
  }

  .stepUp5-title {
    max-width: 35.1875rem;
    margin: 0 auto 2.5rem;
  }

  .stepUp4-title {
    margin-bottom: 48px;
  }

  &-actions {
    padding-top: 1.875rem;
  }

  @include mq($until: md) {
    .button {
      width: 100%;
    }
  }

  @include mq(md) {
    padding: 3.375rem 1rem;
  }

  @include mq(lg) {
    padding: 4.25rem 1rem 4.5rem;
  }
}

// Brick Md

.brick-md {
  padding: 32px 1rem;
  &:not(.brick-gray) {
    + .brick-md {
      &:not(.brick-gray) {
        padding-top: 0.5rem;
      }
    }
  }

  .stepUp2-title,
  .stepUp4-title {
    margin-bottom: 1rem;
  }

  @include mq($from: 601px) {
    &.innerWrapper {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @include mq(md) {
    padding: 3rem 1rem;
  }
}

// Brick sm

.brick-sm {
  padding: 1.5rem 1rem 2.25rem;

  .stepUp3-title {
    max-width: 47.5rem;
    margin: 0 auto;
  }

  @include mq(md) {
    padding: 2.25rem 1rem 3.375rem;
  }

  @include mq(lg) {
    padding: 3rem 1rem 4.375rem;
  }
}

// Brick List

.brick-list {
  margin-top: 1rem;
  margin-bottom: 2.5rem;

  @include mq(md) {
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;
  }

  .stepUp2-title {
    margin-bottom: 1rem;
    text-align: center;
  }
}

// Info Card

.infoCard {
  $self: &;
  background: $dark-blue-800;
  color: $white;
  padding: 24px 40px;
  margin: 0 auto 16px;
  max-width: 370px;
  border-radius: 4px;
  text-align: center;

  &.is-light {
    background: $gray-50;
    color: $dark-blue-900;

    #{$self}-date {
      color: $green-800;
    }

    #{$self}-title {
      @include typo-rwd(stapel-step-up-2);
    }
  }

  &-actions {
    padding: 30px 0 10px;
  }

  &-header {
    padding-bottom: 12px;
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
  }

  &-subtitle {
    @include typo-rwd(dm-sans-step-down-1-regular);
    position: relative;
    padding-bottom: 1rem;

    &:after {
      content: '';
      width: 0.25rem;
      height: 0.25rem;
      background: $white;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }

  &-date {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    color: $green-200;
    margin-bottom: 4px;
  }

  &-description {
    @include typo-rwd(dm-sans-step-down-1-regular);
    opacity: 0.6;
  }

  @include mq(md) {
    &-title {
      @include typo-rwd(stapel-step-up-2);
    }

    &-date {
      @include typo-rwd(dm-sans-step-down-1-bold-caps);
      text-transform: uppercase;
      color: $green-200;
    }

    &-group {
      display: flex;
      justify-content: center;
      .infoCard {
        flex: 0 0 calc(50% - 16px);
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}

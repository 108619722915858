// Simple banner

.simpleBanner {
  $self: &;
  background: $dark-blue-900;
  color: $white;
  position: relative;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .print {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 30%;
    z-index: 1;
  }

  &-info {
    max-width: 44.4375rem;
    position: relative;
    z-index: 2;
    max-width: 13.125rem;
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
  }

  .wrapper {
    width: 100%;
  }

  &.is-generic {
    padding-top: 5.125rem;
    padding-bottom: 2rem;
    min-height: 11.75rem;
    text-align: center;

    #{$self}-info {
      margin: 0 auto;
    }
  }

  @include mq($until: md) {
    &.is-generic {
      .print {
        object-position: 90%;
      }
    }
  }

  @include mq(sm) {
    min-height: 10rem;

    &-info {
      max-width: 17.25rem;
    }
  }

  @include mq(md) {
    min-height: 13.75rem;

    &-title {
      @include typo-rwd(stapel-step-up-2);
    }

    &-info {
      max-width: 25.875rem;
    }

    &.is-generic {
      padding-top: 6.875rem;
      padding-bottom: 3.375rem;
      .print {
        object-position: 70% 0;
      }
    }
  }

  @include mq(lg) {

    &-title {
      @include typo-rwd(stapel-step-up-3);
    }

    &-info {
      max-width: 35.25rem;
    }

    .print {
      object-position: 0;
    }

    &.is-generic {
      padding-top: 12.75rem;
      padding-bottom: 5.25rem;
    }
  }

  @include mq(xl) {
    min-height: 18.75rem;

    &-title {
      @include typo-rwd(stapel-step-up-4);
    }

    &-info {
      max-width: 44.4375rem;
    }

    &.is-generic {
      padding-top: 13.1875rem;
      padding-bottom: 3.9375rem;
    }
  }

  @include mq(xxl) {
    &-title {
      @include typo-rwd(stapel-step-up-5);
    }

    &.is-generic {
      padding-bottom: 7rem;
    }
  }
}

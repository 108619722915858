// Banner

.cta {
  background: $dark-blue-900;
  color: $white;
  text-align: center;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 64px;
  padding-bottom: 60px;
  position: relative;

  .print {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 2;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: relative;
      z-index: 0;
    }
  }

  .stepUp5-title {
    max-width: 760px;
    margin: 0 auto 28px;
  }

  .wrapper {
    z-index: 3;
    position: relative;
  }

  @include mq($until: sm) {
    .button {
      width: 100%;
    }
  }

  @include mq(lg) {
    padding-top: 128px;
    padding-bottom: 118px;

    .stepUp5-title {
      margin-bottom: 40px;
    }
  }

  &-message {
    margin-top: 1.25rem;
  }
}

// Cta Not cropped

.cta-notCropped {
  position: relative;
  overflow: hidden;

  @include mq(md) {
    .print {
      object-fit: contain;
      height: auto;
      max-height: 98%;
    }

    &:before {
      content: url('../../../assets/images/print-pink-single-left.svg');
      position: absolute;
      top: 0;
      left: 0;
      width: 12.5rem;
    }
  }

  @include mq(lg) {
    &:before {
      left: -0.625rem;
      width: 20rem;
    }
  }

  @include mq(xl) {
    &:before {
      width: 26.25rem;
    }
  }
}

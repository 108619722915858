// Social Group

.socialGroup {
  .socialItems {
    display: flex;
  }

  .socialItem {
    &:not(:last-of-type) {
      margin-right: 1.25rem;
    }

    .bg {
      fill: $dark-blue-500;
    }

    .bg,
    .shape {
      transition: all $color-change ease;
    }

    &:hover {
      .bg {
        fill: $white;
      }

      .shape {
        fill: $dark-blue-900;
      }
    }
  }

  @include mq($until: lg) {
    .socialItems {
      justify-content: center;
    }

    .socialItem {
      svg {
        width: 3.25rem;
        height: 3.25rem;
      }
    }
  }
}

.socialItem {
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
}

.helperCard {
  display: block;
  position: relative;
  padding: 0.75rem;
  padding-left: 1rem;
  min-height: 4.75rem;
  background-color: $blue-25;
  border-radius: 0.25rem;
  box-sizing: border-box;

  & + .helperText {
    margin-top: 0.5rem;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0.25rem;
    height: 100%;
    background-image: linear-gradient(to bottom, $green-300, $blue-300);
  }

  &-title {
    @include typo-rwd(dm-sans-step-down-2-bold);
    display: block;
    position: relative;
    color: $gray-900;
  }

  &-description {
    @include typo-rwd(dm-sans-step-down-2-regular);
    display: block;
    position: relative;
    margin-top: 0.125rem;
    color: $gray-600;
  }
}

// Summary Round

.summaryRound {
  $self: &;
  + .summaryRound {
    padding-top: 3.5rem;
  }
  .stepUp2-title {
    margin-bottom: 1.25rem;
    span {
      margin-right: 0.375rem;
    }
  }

  .roundMatch {
    margin-bottom: 1rem;
  }

  .bracketCard {
    max-width: none;
  }

  &-actions {
    text-align: center;
  }

  @include mq($until: lg) {
    .roundMatch {
      flex-basis: 100%;
    }
    &-actions {
      padding-top: 1.625rem;
      text-align: center;
      .button {
        width: 100%;
        display: flex;
        max-width: 17.25rem;
        margin-left: auto;
        margin-right: auto;
        + .button {
          margin-top: 1.5rem;
        }
      }
    }
  }

  @include mq(md) {
    &-items {
      display: flex;
      flex-wrap: wrap;
    }

    .roundMatch {
      flex: 0 0 calc(50% - 0.5rem);
      margin-right: 1rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @include mq(lg) {
    .roundMatch {
      flex-basis: calc(33.3% - 0.875rem);
      margin-right: 1.25rem;
      &:nth-child(2n) {
        margin-right: 1.25rem;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &-actions {
      padding-top: 2.5rem;
      .button {
        + .button {
          margin-left: 1.5rem;
        }
      }
    }
  }

  @include mq(xxl) {
    .roundMatch {
      margin-bottom: 2.25rem;
      flex-basis: calc(25% - 1.6875rem);
      margin-right: 2.25rem;
      &:nth-child(2n) {
        margin-right: 2.25rem;
      }
      &:nth-child(3n) {
        margin-right: 2.25rem;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

// Summary Round full

.summaryRound {
  $self: &;

  &-full {
    #{$self}-actions {
      display: none;
    }
    #{$self}:last-child {
      #{$self}-actions {
        display: block;
      }
    }
  }

  @include mq($until: sm) {
    &-full {
      .button {
        max-width: none;
      }
    }
  }

  @include mq($until: lg) {
    &-full {
      .sticky {
        #{$self}-actions {
          display: block;
          z-index: 10;
          width: 100%;
          height: 5.625rem;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 1rem;
          animation: bottom-appear $general-change;
          .button {
            width: 100%;
          }
        }
      }
    }
  }

  @include mq(md) {
    &-full {
      .button {
        max-width: 18rem;
      }
      #{$self} {
        &:first-child,
        &:last-child {
          #{$self}-actions {
            display: block;
          }
        }
      }
    }
  }
}

// Brackets Slot

.bracketsSlot {
  padding: 2rem 1rem;

  @include mq(md) {
    padding: 2.5rem 1rem;
  }

  @include mq(lg) {
    padding: 4rem 1rem;
  }

  &-title {
    @include typo-rwd(stapel-step-up-4);
    margin: 0 auto 1.75rem;
    text-align: center;
  }

  &-content {
    text-align: center;

    // MODIFIES COMPONENTS INSIDE
    @include mq($until: lg) {
      > .button {
        width: 100%;
        display: flex;
        max-width: 17.25rem;
        margin-left: auto;
        margin-right: auto;
        + .button {
          margin-top: 1.5rem;
        }
      }
    }
    @include mq(lg) {
      > .button {
        + .button {
          margin-left: 1.5rem;
        }
      }
    }

    .tabs-wrapper {
      padding: 1.25rem 0 0;
      text-align: left;
    }
  }
}

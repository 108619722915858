// User intro

.userIntro {
  background: $dark-blue-900;
  color: $white;
  padding: 6.375rem 1rem 4.5rem;
  text-align: center;

  + .hero {
    padding-top: 2.5rem;
    min-height: 0;
  }

  .stepUp2-text,
  .stepUp4-title {
    max-width: 35.1875rem;
    margin-left: auto;
    margin-right: auto;
  }

  .stepUp2-text {
    margin-bottom: 0.75rem;
  }

  .stepUp4-title {
    margin-bottom: 2rem;
  }

  &-subtitle {
    @include typo-rwd(dm-sans-baseline-regular);
    max-width: 23rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }

  .dividerText {
    @include typo-rwd(dm-sans-step-down-1-bold-caps);
    text-transform: uppercase;
    opacity: 0.87;
    &:before,
    &:after {
      content: '-';
      padding: 0 0.1875rem;
    }
  }

  .genericLabel {
    color: $white;
    margin-bottom: 0.75rem;
  }

  &-content {
    padding: 2.5rem 0 3.125rem;
  }

  .card {
    background: $dark-blue-800;
    width: 100%;
    max-width: 23rem;
    margin: 0 auto 1.5rem;
    text-align: center;
    padding: 1.5rem 1.5rem 2.25rem 1.5rem;
    border-radius: 0.25rem;

    .button {
      margin-top: 0.625rem;
    }
  }

  .socialItems {
    justify-content: center;
    margin-top: 1.75rem;
  }

  .socialItem {
    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  .caption {
    @include typo-rwd(dm-sans-step-down-1-regular);
    opacity: 0.87;
    margin-top: 0.75rem;
    color: $white;
  }

  .link {
    color: $blue-200;
  }

  @include mq(lg) {
    padding: 11.625rem 1rem 4.5rem;
    .cards {
      display: flex;
      justify-content: center;
    }

    .card {
      margin: 0 0.75rem;
    }
  }
}

// Banners

.banner {
  $self: &;
  background: $dark-blue-900;
  color: $white;
  position: relative;
  overflow: hidden;

  .print {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 16% 50%;
    z-index: 2;
  }

  &-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--about {
    #{$self}-image {
      max-width: 47.5rem;
    }
    .image {
      object-position: 100% 50%;
    }
  }

  &-info {
    position: relative;
    z-index: 3;
  }

  .stepUp5-title {
    margin-bottom: 1rem;
    max-width: 44.5rem;
  }

  .wrapper {
    position: relative;
  }

  .stepUp2-text {
    opacity: 0.8;
    margin-bottom: 1rem;
    max-width: 44.5rem;
  }

  .button {
    margin-top: 1.5rem;
  }

  &.has-right-image {
    #{$self}-image {
      left: auto;
      right: 0;
    }
  }

  &--about {
    #{$self}-info {
      padding: 2.5rem 1rem 12.875rem 1rem;
    }
  }

  &--ecopa {
    #{$self}-info {
      padding: 2.5rem 1rem 6.875rem 1rem;
    }

    .stepUp2-text {
      color: $gray-50;
      strong {
        color: $white;
      }
    }

    #{$self}-image {
      text-align: right;
      img {
        max-width: 50rem;
      }
    }
  }

  @include mq($until: md) {
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 4, 41, 1) 0%,
        rgba(0, 4, 41, 0) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .button {
      width: 100%;
    }

    &--ecopa {
      #{$self}-image {
        width: 50%;
        img {
          object-position: 25%;
        }
      }
    }
  }

  @include mq(md) {
    &--about {
      #{$self}-info {
        padding: 1.875rem 1.25rem 6.875rem 50%;
      }
    }

    &--ecopa {
      #{$self}-info {
        padding-right: 50%;
      }

      #{$self}-image {
        img {
          object-position: 34% 0;
        }
      }
    }

    &-image {
      width: 50%;
    }
  }

  @include mq(lg) {
    .print {
      object-position: 22% 50%;
    }

    &--about {
      #{$self}-info {
        padding: 8.125rem 1.25rem 8.125rem 50%;
      }
    }

    &--ecopa {
      min-height: 37.5rem;
      #{$self}-info {
        padding-top: 4.25rem;
        padding-bottom: 6rem;
      }
    }
  }

  @include mq(xl) {
    .print {
      object-position: 0 50%;
    }

    &--about {
      #{$self}-info {
        padding-left: calc(50% + 2.5rem);
        padding-top: 8.125rem;
        padding-bottom: 8.125rem;
      }
    }

    &.has-right-image {
      #{$self}-info {
        padding-right: 50%;
        padding-left: 0;
      }
    }

    &--ecopa {
      #{$self}-image {
        img {
          object-position: 20% 0;
        }
      }
    }
  }

  @include mq(xxl) {
    &--about {
      #{$self}-info {
        padding-top: 11.375rem;
        padding-bottom: 11.375rem;
      }
    }

    &--ecopa {
      #{$self}-info {
        padding-bottom: 10.25rem;
      }

      #{$self}-image {
        img {
          object-position: 100% 0;
        }
      }
    }
  }
}

// Counter banner

.counterBanner {
  text-align: center;
  color: $dark-blue-900;
  padding: 1.375rem 0;

  &-number {
    @include typo-rwd(stapel-step-up-5);
  }

  &-content {
    padding: 0.9375rem 0;
  }

  &-ball {
    width: 2.25rem;
  }

  @include mq(lg) {
    padding: 4.5rem 0;

    &-ball {
      width: 2.75rem;
    }

    &-content {
      padding: 2.75rem 0;
    }

    &-number {
      @include typo-rwd(stapel-step-up-6);
    }
  }
}

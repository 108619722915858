// Headings (Stapel)

.stepUp6-title {
  @include typo-rwd(stapel-step-up-4);
  @include mq(lg) {
    @include typo-rwd(stapel-step-up-6);
  }
}

.stepUp5-title {
  @include typo-rwd(stapel-step-up-3);
  @include mq(lg) {
    @include typo-rwd(stapel-step-up-5);
  }
}

.stepUp4-title {
  @include typo-rwd(stapel-step-up-2);
  @include mq(md) {
    @include typo-rwd(stapel-step-up-3);
  }
  @include mq(lg) {
    @include typo-rwd(stapel-step-up-4);
  }
}

.stepUp3-title {
  @include typo-rwd(stapel-step-up-1);
  @include mq(lg) {
    @include typo-rwd(stapel-step-up-3);
  }
}

.stepUp2-title {
  @include typo-rwd(stapel-step-up-2);
}

.stepUp1-title {
  @include typo-rwd(stapel-step-up-1);
}

.stepDown1-title {
  @include typo-rwd(stapel-step-down-1-caps-bold);
  text-transform: uppercase;
}

// Texts (DM Sans)

.baseline-text {
  @include typo-rwd(dm-sans-baseline-regular);
}

.stepDown1-text {
  @include typo-rwd(dm-sans-step-up-1-regular);
}

.stepUp1-text {
  @include typo-rwd(dm-sans-step-down-1-regular);
}

.stepUp2-text {
  @include typo-rwd(dm-sans-step-up-1-regular);
  @include mq(md) {
    @include typo-rwd(dm-sans-baseline-regular);
  }
  @include mq(lg) {
    @include typo-rwd(dm-sans-step-up-2-regular);
  }
}

.stepUp3-text {
  @include typo-rwd(dm-sans-step-up-2-regular);
  @include mq(md) {
    @include typo-rwd(dm-sans-step-up-1-regular);
  }
  @include mq(lg) {
    @include typo-rwd(dm-sans-step-up-3-regular);
  }
}

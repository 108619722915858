// User private area

.mobileSubmenu {
  width: 100%;
  color: $white;
  padding: 1rem 0;

  &-title {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    opacity: 0.6;
    margin-bottom: 0.75rem;
    padding: 0 1rem;
  }

  &-list {
    width: 100%;
  }

  &-itemWrapper {
    width: 100%;
    &:hover {
      color: $blue-400;
      background: $dark-blue-700;
    }
  }

  &-item {
    @include typo-rwd(dm-sans-baseline-bold);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    padding: 16px;
    width: 100%;
    transition: all $general-change;
    text-align: left;

    .icon {
      margin-left: 0.75rem;
      margin-top: 0.125rem;
    }
  }

  @include mq($from: md, $until: xxl) {
    &-title {
      @include typo-rwd(dm-sans-step-down-1-bold-caps);
      font-size: 1.125rem;
      margin-right: auto;
      margin-left: auto;
      padding-left: 2rem;
    }

    &-item {
      @include typo-rwd(dm-sans-step-up-2-bold);
      padding: 1.75rem 2rem;
    }
  }
}

// Utils

.genericLabel {
  @include typo-rwd(dm-sans-baseline-regular);
  display: block;
  position: relative;
  color: $gray-900;

  & + * {
    margin-top: 1rem;
  }
}

.optional-label {
  @include typo-rwd(dm-sans-step-down-1-regular);
  color: $gray-400;
  padding: 0 0.25rem;
}

.is-disabled {
  color: $gray-300;
  .optional-label {
    color: $gray-300;
  }
}

.has-divider {
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 2px solid $gray-100;
}

.caption {
  @include typo-rwd(dm-sans-step-down-2-regular);
  color: $gray-600;
}

.helperText {
  @include typo-rwd(dm-sans-step-down-2-regular);
  display: block;
  position: relative;
  color: $gray-600;

  & + .helperText {
    margin-top: 0.5rem;
  }
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.rounded-image {
  border-radius: 50%;
  width: 7.75rem;
  height: 7.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $blue-25;
}

.is-dark {
  .rounded-image {
    background: $dark-blue-700;
  }
}

.errorText {
  @include typo-rwd(dm-sans-step-down-2-regular);
  display: block;
  padding: 0 1rem;
  bottom: 0;
  left: 0;
  color: $red-700;
  box-sizing: border-box;
}

.innerWrapper {
  max-width: calc(47.5rem + 2rem);
  margin: 0 auto;
  padding: 0 1rem;

  &.size-sm {
    max-width: calc(22.9375rem + 2rem);
  }

  &.size-md {
    max-width: calc(28.75rem + 2rem);
  }

  &.size-lg {
    max-width: calc(35.25rem + 2rem);
  }

  &.no-padded {
    padding: 0;

    @include mq(md) {
      padding: 0 1rem;
    }
  }
}

.centered-block {
  text-align: center;
  padding-bottom: 60px;
}

.svg-filters {
  position: absolute;
  left: -50000em;
  height: 0;
  width: 0;
}

// File name

.fileName {
  display: flex;
  align-items: center;
  animation: fade-in $general-change;

  &-error {
    padding: 4px 0 0 0;
  }

  &-icon {
    flex: 0 0 auto;
    margin-right: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $yellow-500;
    background: linear-gradient(
      180deg,
      rgba(255, 229, 9, 1) 0%,
      rgba(255, 212, 5, 1) 75%,
      rgba(254, 191, 0, 1) 100%
    );
    text-align: center;
  }

  &-info {
    flex: 1 1 auto;
    min-width: 0;
  }

  &-name {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-900;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    min-width: 0;
  }

  &-size {
    @include typo-rwd(dm-sans-step-down-2-regular);
    color: $gray-600;
  }

  .button-icon {
    &:hover {
      .icon {
        color: $blue-400;
      }
    }
  }
}

// Avatar uploader

.avatarUploader {
  border: 1px solid $gray-300;

  padding: 24px;
  border-radius: 4px;

  &-image {
    flex: 0 0 200px;
    height: 200px;
    text-align: center;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &-content {
    padding-top: 30px;
    text-align: center;
  }

  .inputFile-field {
    width: auto;
    max-width: 212px;
    margin: 0 auto 16px auto;
  }

  .helperText {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
  }

  @include mq(md) {
    display: flex;
    align-items: center;

    &-content {
      flex: 1 1 auto;
      padding: 0 0 0 30px;
      text-align: center;
    }
  }
}

// ------------------------------------
// $BASE
// ------------------------------------

// Tipos
// @include font-face('AvantGardeStd-Demi', 'ITCAvantGardeStd-Demi');

// Global
* {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
}

body {
  @include typo-rwd(body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Content
picture {
  display: flex;
}

iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    outline: none;
  }
}

.wrapper {
  max-width: $layout-maxWidth;
  margin: 0 auto;

  @include mq(xxl) {
    max-width: $layout-maxWidth-xl;
  }
}

.invisible {
  display: none;
}

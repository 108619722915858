// Countdown

.countdown {
  background: $white;
  box-shadow: $elevation2;
  text-align: center;
  padding: 1.75rem 1.5rem 1.5rem 1.5rem;
  max-width: 47.5rem;
  border-radius: 0.25rem;
  height: 12.5rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &-wrapper {
    padding: 0.75rem;

    .countdown {
      position: relative;
      top: -60px;
      z-index: 10;
    }
  }

  .genericLabel {
    color: $gray-600;
  }

  &-content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.625rem 0 0.375rem;
  }

  &-item {
    padding: 0.5rem;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }

    .value {
      @include typo-rwd(stapel-step-up-5);
      line-height: 1;
    }

    .label {
      @include typo-rwd(stapel-step-up-1);
      position: relative;
      top: -0.375rem;
    }

    &.is-single {
      .value {
        @include typo-rwd(stapel-step-up-3);
      }
    }
  }

  // Media queries

  @include mq($until: 400px) {
    &-item {
      margin-right: 0.125rem;
    }
  }

  @include mq($until: sm) {
    &-wrapper {
      .countdown {
        top: -2.5rem;
      }
    }
  }

  @include mq(md) {
    height: 13.5rem;

    &-item {
      padding: 0 2.25rem;
      margin-right: 1.5rem;
      .value {
        @include typo-rwd(stapel-step-up-6);
      }
      .label {
        @include typo-rwd(stapel-step-up-2);
      }

      &.is-single {
        .value {
          @include typo-rwd(stapel-step-up-5);
        }
      }
    }
  }
}

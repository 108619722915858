.errorCard {
  display: block;
  position: relative;
  padding: 1.25rem;
  background-color: $red-50;
  border: 2px solid $red-700;
  border-radius: 0.25rem;
  box-sizing: border-box;

  &-title {
    @include typo-rwd(dm-sans-step-down-1-bold);
    color: $red-700;
  }

  &-list {
    display: block;
    position: relative;
    margin-top: 1rem;
  }

  &-description {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $red-700;
    margin-top: 0.5rem;
  }

  &-cta {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $red-700;
    margin-top: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
  }

  &-list li {
    @include typo-rwd(dm-sans-step-down-1-regular);
    position: relative;
    padding-left: 0.75rem;
    color: $red-700;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.25rem;
      height: 0.25rem;
      background-color: $red-700;
      border-radius: 0.25rem;
      transform: translateY(-50%);
    }

    & + li {
      margin-top: 0.5rem;
    }
  }
}

// Detail Player

.detailPlayer {
  $self: &;
  text-align: center;
  padding-bottom: 2rem;

  &-profile {
    #{$self}-subtitle {
      color: $gray-600;
      @include typo-rwd(dm-sans-baseline-regular);
      margin-bottom: 0.5rem;
    }
  }

  &-avatar {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 1.25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .default-avatar {
      width: 200px;
      height: 200px;
    }
  }

  &-title {
    @include typo-rwd(stapel-step-up-3);
    color: $gray-900;
    margin-bottom: 0.5rem;
  }

  &-subtitle,
  &-extraInfo {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-400;
  }

  &-subtitle {
    margin-bottom: 1rem;
  }

  .baseline-text,
  .stepDown1-text {
    margin-bottom: 0.5rem;
    color: $gray-400;
    .is-dark {
      color: $gray-600;
    }
  }

  .button {
    margin-top: 1.25rem;
  }

  .federation {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: 0.0313rem;
    color: $gray-400;
    margin-top: 1rem;

    &:before,
    &:after {
      content: '-';
      padding: 0 0.25rem;
      position: relative;
      top: -0.125rem;
    }
  }

  @include mq(md) {
    padding-bottom: 2.5rem;
  }
}

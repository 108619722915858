// Tables

.table {
  min-width: 600px;
  width: 100%;
  &-wrapper {
    overflow-x: auto;
  }
  tr,
  th {
    font-weight: normal;
    border: 1px solid $gray-200;
    padding: 0.625rem;
  }

  th {
    &:after {
      content: '';
      display: inline-block;
      vertical-align: top;
      min-height: 20px;
    }
  }

  &-header {
    th {
      font-weight: bold;
      background: $gray-100;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

@font-face {
  font-family: 'Stapel';
  src: url('../../fonts/stapel.woff') format('woff'),
    url('../../fonts/stapel.woff2') format('woff2');
}

$stapel: 'Stapel', sans-serif;
$dm-sans: 'DM Sans', sans-serif;

$font-families: (
  stapel: $stapel,
  dm-sans: $dm-sans,
);

$typography: (
  body: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1rem,
        lh: normal,
      ),
    ),
  ),
  stapel-step-up-6: (
    format: (
      xs: (
        ff: stapel,
        fz: 3.75rem,
        lh: 4.5rem,
      ),
    ),
  ),
  stapel-step-up-5: (
    format: (
      xs: (
        ff: stapel,
        fz: 3rem,
        lh: 3.625rem,
      ),
    ),
  ),
  stapel-step-up-4: (
    format: (
      xs: (
        ff: stapel,
        fz: 2.25rem,
        lh: 3rem,
        ls: 0.0125rem,
      ),
    ),
  ),
  stapel-step-up-3: (
    format: (
      xs: (
        ff: stapel,
        fz: 1.75rem,
        lh: 2.25rem,
        ls: 0.0125rem,
      ),
    ),
  ),
  stapel-step-up-2: (
    format: (
      xs: (
        ff: stapel,
        fz: 1.25rem,
        lh: 1.625rem,
        ls: 0.2px,
      ),
    ),
  ),
  stapel-step-up-1: (
    format: (
      xs: (
        ff: stapel,
        fz: 1.125rem,
        lh: 1.5rem,
        ls: 0.2px,
      ),
    ),
  ),
  stapel-step-down-1-caps-bold: (
    format: (
      xs: (
        ff: stapel,
        fz: 0.875rem,
        lh: 1.125rem,
        ls: 0.0375rem,
      ),
    ),
  ),
  dm-sans-step-up-4-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 2.25rem,
        fw: $bold,
        lh: 3rem,
        ls: 0.2px,
      ),
    ),
  ),
  dm-sans-step-up-4-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 2.25rem,
        fw: $regular,
        lh: 3rem,
      ),
    ),
  ),
  dm-sans-step-up-3-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1.75rem,
        fw: $bold,
        lh: 2.25rem,
      ),
    ),
  ),
  dm-sans-step-up-3-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1.75rem,
        fw: $regular,
        lh: 2.25rem,
      ),
    ),
  ),
  dm-sans-step-up-2-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1.25rem,
        fw: $bold,
        lh: normal,
      ),
    ),
  ),
  dm-sans-step-up-2-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1.25rem,
        fw: $regular,
        lh: normal,
      ),
    ),
  ),
  dm-sans-step-up-1-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1.125rem,
        fw: $bold,
        lh: 1.5rem,
      ),
    ),
  ),
  dm-sans-step-up-1-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1.125rem,
        fw: $regular,
        lh: 1.5rem,
      ),
    ),
  ),
  dm-sans-baseline-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1rem,
        fw: $bold,
        lh: normal,
        ls: 0.2px,
      ),
    ),
  ),
  dm-sans-baseline-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 1rem,
        fw: $regular,
        lh: normal,
        ls: 0.2px,
      ),
    ),
  ),
  dm-sans-step-down-1-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.875rem,
        fw: $bold,
        lh: 1.125rem,
        ls: 0.2px,
      ),
    ),
  ),
  dm-sans-step-down-1-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.875rem,
        fw: $regular,
        lh: 1.125rem,
        ls: 0.2px,
      ),
    ),
  ),
  dm-sans-step-down-1-bold-caps: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.875rem,
        fw: $bold,
        lh: 1.125rem,
        ls: 0.5008px,
      ),
    ),
  ),
  dm-sans-step-down-1-regular-caps: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.875rem,
        fw: $regular,
        lh: 1.125rem,
        ls: 0.5008px,
      ),
    ),
  ),
  dm-sans-step-down-2-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.75rem,
        fw: $bold,
        lh: 1rem,
        ls: 0.2px,
      ),
    ),
  ),
  dm-sans-step-down-2-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.75rem,
        fw: $regular,
        lh: 1rem,
        ls: 0.0125rem,
      ),
    ),
  ),
  dm-sans-step-down-2-bold-caps: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.75rem,
        fw: $bold,
        lh: 1rem,
        ls: 0.0313rem,
      ),
    ),
  ),
  dm-sans-step-down-2-regular-caps: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.75rem,
        fw: $regular,
        lh: 186x,
        ls: 0.0313rem,
      ),
    ),
  ),
  dm-sans-step-down-3-bold: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.625rem,
        fw: $bold,
        lh: 0.875rem,
        ls: 0.0125rem,
      ),
    ),
  ),
  dm-sans-step-down-3-regular: (
    format: (
      xs: (
        ff: dm-sans,
        fz: 0.625rem,
        fw: $regular,
        lh: 0.875rem,
        ls: 0.0125rem,
      ),
    ),
  ),
);

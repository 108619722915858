// Input file

.inputFile {
  $self: &;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  width: 100%;

  &-field {
    position: relative;
    width: 100%;
    max-width: 420px;
    height: 56px;
    &:hover {
      #{$self}-label {
        background: $dark-blue-900;
        border-color: $dark-blue-900;
        color: $white;
      }
    }
  }

  &:focus {
    + #{$self}-label {
      background: $dark-blue-900;
      color: $white;
      outline: 1px dotted $dark-blue-900;
      outline: -webkit-focus-ring-color auto 0px;
    }
  }

  + #{$self}-label {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.0125rem;
    padding: 10px 20px;
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    font-weight: $bold;
    cursor: pointer;
    outline: none;
    min-height: 3.5rem;
    transition: all $color-change ease;
    background: $white;
    border-color: $dark-blue-900;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    &:hover {
      background: $dark-blue-900;
      color: $white;
    }
  }
}

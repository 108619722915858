// Sponsors

.sponsors {
  .slick-next,
  .slick-prev {
    fill: $white;
    top: 50%;
    transform: translateY(-50%);
  }
  .slick-disabled {
    opacity: 0.6;
  }

  .sponsor {
    margin: 0 auto;
    display: inline-block;
  }

  .stepUp5-title {
    max-width: none;
  }

  @include mq(md) {
    .sponsor {
      padding: 0 20px;
    }
  }
}

// Sponsor Row

.sponsorRow {
  padding-bottom: 28px;

  &-title {
    @include typo-rwd(dm-sans-baseline-bold);
    color: $green-200;
    margin: 30px 0;
  }
}

// Sponsor full width banner

.sponsorFull {
  width: 100%;
  height: auto;
  img {
    display: block;
    // min-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  @include mq(xl) {
    // height: 400px;
  }
}

// List Card

.listCard {
  $self: &;
  display: flex;
  padding: 2rem 0.5rem 0.75rem;
  color: $white;
  position: relative;

  @include mq(md) {
    padding: 2.25rem 0.75rem 0.75rem;
  }

  // SPACING
  & + & {
    margin-top: 1rem;
  }

  // TYPES
  &--active,
  &--future,
  &--future-highlight,
  &--future-closed {
    background-color: $dark-blue-900;
  }

  &--active {
    --filter: #{$red-800};
    --status-bg: #{$red-800};
  }

  &--future {
    --filter: #{$dark-blue-900};
    --status-bg: #{$blue-800};
  }

  &--future-highlight {
    --filter: #{$purple-900};
    --status-bg: #{$purple-800};
  }

  &--future-closed {
    --filter: #{$dark-blue-900};
    --status-bg: #{$gray-600};
  }

  &--finished {
    --filter: #{$gray-900};
    --gradient: linear-gradient(
      113deg,
      rgba(23, 25, 26, 0) 4%,
      #{$gray-900} 100%
    );
    --status-bg: #{$gray-200};
    --status-text: #{$gray-900};
    background-color: $gray-900;
  }

  // CONTAINERS
  &-info {
    flex-grow: 1;
    z-index: 1;

    @include mq(md) {
      margin-top: -1.75rem;
    }
  }

  &-aside {
    flex-basis: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;

    @include mq(md) {
      flex-basis: 5rem;
    }
  }

  &-image-compose {
    @include full-space;

    #{$self}--active & {
      &::before {
        @include full-space(3);
        content: '';
        background: linear-gradient(
          35deg,
          rgba($purple-500, 0.47),
          rgba($red-800, 0.47),
          rgba($red-400, 0.47),
          rgba($red-800, 0.47)
        );
        background-size: 400% 400%;
        animation: gradient 6s ease infinite;
      }
    }
  }

  &-image {
    @include full-space(2);
    overflow: hidden;

    &::before {
      @include full-space(2);
      content: '';
      width: 100%;
      height: 100%;
      background-color: var(--filter, $red-800);
      mix-blend-mode: color;

      @include mq(md) {
        width: 50%;
      }
    }

    &::after {
      @include full-space(2);
      content: '';
      width: 100%;
      height: 100%;
      background-image: var(
        --gradient,
        linear-gradient(113deg, rgba(0, 4, 41, 0) 4%, $dark-blue-900 100%)
      );

      @include mq(md) {
        width: 50%;
      }
    }

    > img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 0;
      filter: url(#svgMonotoneGradientBlur);

      @include mq(md) {
        width: 50%;
      }
    }
  }

  // CHILDS
  &-game {
    display: block;
    margin-bottom: 0.25rem;
    @include typo-rwd(dm-sans-step-down-2-bold);

    @include mq(md) {
      margin-bottom: 0.5rem;
      transform: translateY(0.25rem);
    }
  }

  &-title {
    @include typo-rwd(dm-sans-baseline-bold);

    @include mq(md) {
      font-size: 1.125rem;
    }
  }

  &-description {
    margin-bottom: 0.5rem;
    @include typo-rwd(dm-sans-step-down-2-regular);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.65);

    @include mq(md) {
      @include typo-rwd(dm-sans-step-down-1-regular);
    }
  }

  &-date {
    @include typo-rwd(dm-sans-step-down-2-regular);

    @include mq(md) {
      @include typo-rwd(dm-sans-step-down-1-regular);
    }
  }

  &-status {
    @include typo-rwd(dm-sans-step-down-3-bold);
    padding: 0.25rem 0.75rem;
    margin-bottom: 0.25rem;
    border-radius: 4rem;
    text-align: center;
    color: var(--status-text, $white);
    background-color: var(--status-bg, $red-800);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    @include mq(md) {
      margin-bottom: 0.5rem;
      @include typo-rwd(dm-sans-step-down-1-bold);
    }
  }
  &-prize {
    @include typo-rwd(dm-sans-step-down-2-bold);

    @include mq(md) {
      @include typo-rwd(dm-sans-step-down-1-bold);
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.65);
    }
  }
  &-inscriptions {
    @include typo-rwd(dm-sans-step-down-2-regular);
    margin-top: auto;

    @include mq(md) {
      @include typo-rwd(dm-sans-step-down-1-regular);
    }
  }
}

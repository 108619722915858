// Integrations

.tournament-widget {
  $widget-height-sm: 500px;
  $widget-height-lg: 600px;
  $widget-height-xl: 800px;
  padding: 40px 16px;

  .wrapper {
    position: relative;
    text-align: center;
    height: $widget-height-sm;
  }

  .loader-panel {
    width: 100%;
    height: 100%;
    background: $gray-50;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  iframe {
    z-index: 2;
    position: relative;
  }

  &.is-small {
    .wrapper {
      width: 100%;
      max-width: 800px;
      height: 360px;
      margin: 0 auto;
    }
  }

  @include mq(640px) {
    &.is-small {
      .wrapper {
        height: $widget-height-lg;
      }
    }
  }

  @include mq(lg) {
    padding: 60px 16px;

    .wrapper {
      text-align: center;
      height: $widget-height-lg;
    }
  }
  @include mq(xxl) {
    padding: 80px 0;
    .wrapper {
      text-align: center;
      height: $widget-height-xl;
    }
  }
}

// Tournament wrapper

.tournament-wrapper {
  .tournament-widget {
    padding: 3rem 1rem;
  }

  .actions {
    text-align: center;
    padding: 0 1rem 2rem 1rem;

    .button {
      margin-bottom: 1rem;
    }
  }
  @include mq($until: md) {
    .button {
      width: 100%;
      max-width: 20rem;
    }
  }
  @include mq(md) {
    .button {
      margin: 0 0.5rem;
    }
    .actions {
      padding-bottom: 40px;
    }
  }
}

.iframe-wrapper {
  width: 100%;
  padding-top: 56%;
  position: relative;

  .loader-panel {
    width: 100%;
    height: 100%;
    background: $gray-50;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
  }
}

.video {
  &-wrapper {
    max-width: calc(53.3125rem + 2rem);
    margin: 0 auto;
    padding: 0 1rem;
  }
}

.streaming {
  &-block {
    padding: 2.5rem 0;
  }

  &-title {
    @include typo-rwd(dm-sans-step-up-2-bold);
    margin-bottom: 2rem;
    color: $dark-blue-900;
    text-align: center;
  }

  &-info {
    padding-top: 2rem;

    .stepUp4-title {
      margin-bottom: 0.75rem;
    }

    .streaming-text {
      @include typo-rwd(dm-sans-step-down-1-regular);
      color: $gray-600;
    }
  }

  @include mq(md) {
    &-info {
      display: flex;
      padding-top: 2rem;

      .stepUp4-title {
        flex: 1 1 auto;
      }
      .streaming-text {
        flex: 0 0 23.5625rem;
        margin-left: auto;
      }
    }
  }

  @include mq(lg) {
    &-block {
      padding: 3.25rem 0 5rem;
    }
  }
}

.videoBlock {
  text-align: center;
  padding: 2.5rem 1rem;

  .stepUp4-title {
    margin-bottom: 2rem;
  }

  .button {
    margin-top: 2.5rem;
    width: 100%;
  }
  @include mq($until: md) {
    .video-wrapper {
      padding: 0;
    }
  }
  @include mq(md) {
    padding: 3.25rem 1rem 3.75rem;

    .button {
      width: auto;
    }
  }
}

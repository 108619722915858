// panel

.panel {
  $self: &;
  border: 1px solid $gray-200;
  background: $white;
  box-shadow: $elevation1;
  border-radius: 4px;

  &-base {
    text-align: center;
    padding: 1.25rem 1.5rem;
    margin-bottom: 1rem;
    .stepDown1-title {
      margin-bottom: 0.75rem;
    }
  }

  + .panel {
    margin-top: 1.5rem;
  }

  &-header {
    background: $gray-100;
    padding: 16px;
    display: flex;
    align-items: center;
    &.is-centered {
      #{$self}-headerTitle {
        text-align: center;
      }
    }
  }

  &-headerTitle {
    text-align: left;
    @include typo-rwd(dm-sans-baseline-bold);
  }

  &-headerExtra {
    margin-left: auto;
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    color: $gray-900;
    text-align: right;
  }

  &-title,
  &-subtitle {
    text-align: center;
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
    padding: 20px 20px 16px;
  }

  &-subtitle {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    &:before,
    &:after {
      content: '-';
      padding: 0 4px;
    }
  }

  .is-right {
    text-align: right;
  }

  .player-userName {
    color: $gray-600;
  }

  &-content {
    padding: 12px 20px 20px 20px;
  }

  &-actions {
    padding: 1.75rem 0 1rem;
    text-align: center;
  }

  .is-fullWidth {
    padding: 0;
    .button {
      width: 100%;
      max-width: none;
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  .player {
    padding: 8px;
    &:hover {
      background: $gray-50;
    }
  }

  &-row {
    display: flex;
    justify-content: flex-start;
    > .infoPiece,
    > .ecopaLogo,
    > .panel-helperText {
      flex: 1 1 33.3%;
      min-width: 7.5rem;
      &:not(:last-child) {
        padding-right: 0.625rem;
      }
    }
  }

  .infoPiece-detail {
    max-width: 85px;
  }

  .ecopaLogo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    img {
      margin-right: 1rem;
    }
  }

  &-helperText {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    text-align: left;
  }

  @include mq($until: md) {
    &-row {
      flex-wrap: wrap;
      > .infoPiece,
      > .ecopaLogo,
      > .panel-helperText {
        padding-bottom: 0.625rem;
        &:nth-child(3) {
          text-align: left;
        }
      }
    }
  }

  @include mq(md) {
    .half-width {
      .infoPiece {
        padding-left: 32px;
      }
    }

    .infoPiece-detail {
      max-width: 149px;
    }
  }

  @include mq(lg) {
    &-content {
      padding: 20px;
    }

    .infoPiece {
      .half-width {
        padding-left: 20px;
      }
    }

    .infoPiece-detail {
      max-width: 110px;
    }
  }
}

// Detail games

.detailGames {
  .panel {
    margin-bottom: 1rem;
  }
  @include mq(lg) {
    .panel {
      margin-bottom: 2.25rem;
    }
  }
}

.formPanel {
  padding: 1.25rem;

  .stepUp1-title {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
  }

  .input,
  .radioButtonGroup {
    margin-bottom: 1.625rem;
  }

  .radioButtonGroup {
    margin-top: 50px;
  }

  .button {
    width: 100%;
  }

  .errorCard {
    margin: 0 0 24px 0;
  }

  .caption {
    margin-top: 12px;
  }

  .inputFile-field {
    width: 100%;
  }
}

// Icons

.iconWrapper {
  display: block;
  position: relative;
  line-height: 1;

  > svg {
    display: block;
    // outline: 4px solid #f06;
  }
}

.icon {
  path {
    fill: currentColor;
  }
}

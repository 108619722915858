// Competition card

.competitionCard {
  $self: &;
  display: flex;
  align-items: center;
  text-align: left;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  padding: 20px;
  background: $white;
  transition: all $general-change;

  &:hover {
    box-shadow: $elevation2;
    border: 1px solid $gray-200;
  }

  + .competitionCard {
    margin-top: 16px;
  }

  &-image {
    flex: 0 0 54px;
    margin-right: 20px;
  }

  &-info {
    flex: 1 1 auto;
  }

  &-title {
    @include typo-rwd(dm-sans-baseline-bold);
    color: $gray-900;
  }

  &-subtitles {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    margin-bottom: 1px;
    text-transform: capitalize;
  }

  &-icon {
    width: 16px;
  }

  &-wrapper {
    text-align: center;
    padding: 2.5rem 1rem 3.5rem;

    .innerWrapper {
      padding-right: 0;
      padding-left: 0;
    }

    .stepUp2-title {
      margin-bottom: 1rem;
    }

    &.is-gray {
      background: $gray-50;
      padding-top: 2.5rem;
    }

    + #{$self}-wrapper {
      &:not(.is-gray) {
        padding-top: 0;
        margin-top: -0.625rem;
      }
    }
  }
}

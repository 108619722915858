// News

.news {
  position: relative;

  &-info {
    padding: 1rem 1rem 2.25rem 1rem;
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
    color: $dark-blue-900;
    margin-bottom: 0.5rem;
  }

  &-description {
    @include typo-rwd(dm-sans-baseline-regular);
    color: $gray-600;
    margin-bottom: 2.5rem;
  }

  @include mq($until: md) {
    .button {
      width: 100%;
    }
  }

  @include mq(md) {
    &-info,
    &-image {
      width: 50%;
    }

    &-info {
      padding: 2rem 2.875rem 3.5625rem 1.5rem;
    }

    &-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 40% 20%;
      }
    }
  }

  @include mq(lg) {
    &-info {
      padding: 2rem 6.6875rem 3.5625rem 1.5rem;
    }

    &-title {
      @include typo-rwd(stapel-step-up-2);
      margin-bottom: 1rem;
    }

    &-description {
      @include typo-rwd(dm-sans-step-up-1-regular);
    }
  }

  @include mq(xl) {
    &-info {
      padding: 3.25rem 6.6875rem 5.75rem 0;
    }
    &-title {
      @include typo-rwd(stapel-step-up-3);
    }
  }

  @include mq(xxl) {
    &-title {
      @include typo-rwd(stapel-step-up-4);
    }
  }
}

// Teams

.teams-tga {
  background: $dark-blue-900;
  color: $white;
  padding: 2.5rem 1rem 0;

  .stepUp4-title {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  @include mq(md) {
    padding: 4.25rem 1rem;
    .stepUp4-title {
      margin-bottom: 3rem;
    }
  }
}

.team {
  text-align: center;
  padding-bottom: 1.625rem;
  &-title {
    text-align: center;
    font-family: stapel;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: $bold;
    letter-spacing: 0.0313rem;
    margin-bottom: 1rem;
  }

  .portrait {
    &:first-child {
      margin-top: 1.25rem;
    }
  }

  &-member {
    display: inline-block;
    vertical-align: middle;
  }

  @include mq(md) {
    margin-bottom: 3.75rem;
    &-member {
      padding: 0 2.25rem;
    }

    &-title {
      margin-bottom: 2rem;
    }
  }
}

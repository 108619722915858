// Navigation

.mainNav {
  $self: &;
  background-color: rgba($dark-blue-900, 0.4);
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all $color-change ease;
  z-index: 12;

  .wrapper {
    width: 100%;
    position: relative;
    padding-right: 2.875rem;
  }

  .button-menu {
    @include vertical-align(absolute);
    right: 0;
    padding: 0.625rem 0 0.625rem 0.625rem;
  }

  .logo {
    display: block;
    margin-top: 4px;
  }

  .button-icon {
    .icon {
      color: $white;
    }
  }

  .is-selected {
    color: $blue-400;
  }

  &-item {
    display: block;
    color: $white;
    font-size: 1rem;
    font-weight: $bold;
    letter-spacing: 0.0125rem;
    padding: 1rem;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    .button {
      display: flex;
    }
  }

  .user {
    color: $white;
  }

  .logoImage {
    width: 61px;
  }

  &-menu {
    .publicActions {
      justify-content: flex-end;
    }
  }

  // 0 - 600px
  @include mq($until: md) {
    &-menu {
      .publicActions {
        justify-content: flex-end;
      }
    }
    &-actions {
      .button {
        max-width: calc(100% - 32px);
        margin-bottom: 1rem;
      }
    }
  }

  // 600px to 1200px
  @include mq($from: md, $until: xl) {
    &-menu {
      display: flex;
      flex-direction: column;

      #{$self}-list {
        padding-top: 5rem;
      }

      #{$self}-item {
        @include typo-rwd(dm-sans-step-up-2-bold);
        padding: 1.75rem 2rem;
      }

      #{$self}-item,
      #{$self}-actions {
        width: 100%;
      }

      .publicActions {
        justify-content: center;
      }
    }
    &-actions {
      .button {
        max-width: calc(100% - 64px);
        margin-bottom: 1.5rem;
      }
    }
  }

  // 0 - 1200px

  @include mq($until: xl) {
    &.is-animated {
      #{$self}-menu {
        transition: transform $small-item-change;
      }
    }

    &.is-menu-open {
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba($dark-blue-900, 0.4);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        animation: fade-in 0.5s ease;
      }

      #{$self}-menu {
        transform: translateX(0);
      }
    }

    &-menu {
      background: $dark-blue-900;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 80%;
      max-width: 31.5rem;
      transform: translateX(100%);
      overflow-y: auto;

      display: flex;
      flex-direction: column;
    }

    .button-close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }

    &-list {
      padding-top: 4rem;
    }

    &-item {
      transition: all $color-change ease;
      margin: 0 auto;
    }

    &-itemWrapper {
      transition: all $color-change ease;
      &:hover {
        background: $dark-blue-700;
        .mainNav-item {
          color: $blue-400;
        }
      }
    }

    .button {
      min-height: 3.5rem;
      width: 100%;
    }

    &-actions {
      padding: 1rem 0 0 0;
      flex: 1 1 auto;

      .button {
        margin-left: auto;
        margin-right: auto;
        order: 1;
      }
    }
    .publicActions {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  @include mq(lg) {
    .logoImage {
      width: 5.6875rem;
    }
  }

  // Desktop small

  @include mq($from: xl, $until: xxl) {
    &-item {
      margin-left: 1.25rem;
    }

    .logoImage {
      width: 6.25rem;
    }

    .user {
      position: relative;
      margin-left: 1rem;

      .user-avatar {
        margin-right: 0;
      }
      .name {
        display: none;
      }

      .icon-chevron {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;

        margin: 0;
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  // Desktop

  @include mq(xl) {
    padding: 0 1.625rem;

    &-item {
      padding: 0;
      display: inline-block;
      transition: color $color-change ease;

      &:hover {
        color: $blue-400;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      padding-right: 0;
    }

    &-menu {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .is-selected {
      position: relative;
      color: $white;

      &:after {
        content: '';
        width: 100%;
        height: 0.25rem;
        background: $white;
        position: absolute;
        bottom: -1.875rem;
        left: 0;
        animation: left-right $general-change;
      }

      &:hover {
        color: $blue-400;
      }
    }

    &-actions {
      padding: 0;
      margin-left: auto;
      flex-direction: row;
      align-items: center;
      .button {
        margin-left: 16px;
        display: inline-flex;
      }
      &:before {
        display: none;
      }
    }

    &-itemWrapper {
      display: inline-block;
    }

    &-list {
      flex: 1 1 auto;
    }
  }

  // Desktop wide

  @include mq(xxl) {
    &-item {
      margin-left: 2rem;
    }

    .logoImage {
      width: 7.125rem;
    }

    .user {
      margin-left: 1.75rem;
    }
  }
}

// Sticky navigation

.sticky {
  .mainNav {
    padding: 0 1rem;
    background: $dark-blue-900;
  }

  @include mq(lg) {
    .mainNav {
      .logoImage {
        width: 5.625rem;
      }
    }
  }
}

// Buttons

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.0125rem;
  padding: 10px 20px;
  border: 0.125rem solid transparent;
  border-radius: 0.25rem;
  font-weight: $bold;
  cursor: pointer;
  outline: none;
  min-height: 3.5rem;
  transition: all $color-change ease;

  .loader {
    margin-left: 1rem;
    &.primary {
      .gradient-stop {
        stop-color: white;
      }
    }

    &.secondary,
    &.tertiary {
      &.isLight {
        .gradient-stop {
          stop-color: $dark-blue-900;
        }
      }
      .gradient-stop {
        stop-color: white;
      }
    }
  }

  &[disabled] {
    .loader {
      &.primary {
        .gradient-stop {
          stop-color: $gray-300;
        }
      }
    }
  }

  .iconWrapper {
    margin-left: 1rem;
  }

  &[disabled] {
    cursor: default;
  }

  // Button primary

  &--primary {
    background: $red-600;
    color: $white;

    &:hover {
      background: $red-800;
    }

    &:active {
      background: $white;
      color: $dark-blue-900;
    }

    &[disabled] {
      color: #394971;
      background: $dark-blue-700;
    }

    &.is-light {
      &:active {
        background: $dark-blue-900;
        color: $white;
      }
      &[disabled] {
        color: $gray-300;
        background: $gray-100;
        border-color: $gray-100;
      }
    }
  }

  // Button secondary

  &--secondary {
    border-color: $white;

    &:active {
      border-color: $white;
    }

    &[disabled] {
      border-color: $dark-blue-500;
    }

    &.is-light {
      border-color: $dark-blue-900;

      &:active {
        border-color: $dark-blue-900;
      }

      &[disabled] {
        border-color: $gray-200;
      }
    }
  }

  // Button secondary & tertiary

  &--secondary,
  &--tertiary {
    background: transparent;
    color: $white;
    &:hover {
      background: $white;
      color: $dark-blue-900;
    }
    &:active {
      background: $dark-blue-500;
      color: $white;
    }
    &[disabled] {
      color: $dark-blue-500;
      background: transparent;
    }

    &.is-light {
      background: $white;
      color: $dark-blue-900;

      &:hover {
        background: $dark-blue-900;
        color: $white;
      }
      &:active {
        background: $gray-200;
        color: $dark-blue-900;
      }
      &[disabled] {
        color: $gray-200;
        background: transparent;
      }
    }
  }

  &.is-success {
    background: $green-800;
    border-color: $green-800;
    color: $white;

    &[disabled] {
      background: $dark-blue-500;
      border-color: $dark-blue-500;
    }

    &.is-light {
      &[disabled] {
        color: $gray-300;
        background: $gray-100;
        border-color: $gray-100;
      }
    }
  }

  &.is-small {
    padding: 0.25rem 1.5rem;
    min-height: 2.5rem;
  }

  &-icon {
    background: transparent;
    border: none;
    outline: none;
    display: inline-block;
  }

  @include mq(md) {
    padding: 0.8125rem 2rem;
  }
}

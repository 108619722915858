// Social section

.socialSection {
  $self: &;
  background: $gray-100;
  padding: 3.5rem 1rem 0 1rem;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2.25rem;
    max-width: 25rem;
  }

  .twitterTimeline {
    position: relative;
    top: 0.375rem;
    text-align: center;
  }

  .socialItem {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    margin-bottom: 1.75rem;

    &-name {
      @include typo-rwd(dm-sans-baseline-bold);
      margin-left: 0.75rem;
      color: $gray-900;
    }
    .bg {
      fill: $white;
    }
    .shape {
      fill: $gray-900;
    }

    &:hover {
      .bg {
        fill: $gray-900;
      }
      .shape {
        fill: $white;
      }
    }
  }

  &-title {
    @include typo-rwd(stapel-step-up-1);
    margin-bottom: 0.5rem;
  }

  .baseline-text {
    color: $gray-600;
  }

  @include mq($until: md) {
    &-info {
      text-align: center;
      padding-bottom: 2.5rem;
      order: -1;
      max-width: 23.75rem;
      margin: 0 auto;
    }
  }

  @include mq(md) {
    .wrapper {
      flex-direction: row;
      align-items: center;
    }

    .twitterTimeline,
    #{$self}-info {
      flex: 1 1 50%;
    }

    .twitterTimeline {
      text-align: left;
    }

    &-info {
      text-align: left;
      padding-left: 1.25rem;
      max-width: 43.75rem;
    }
  }

  @include mq(lg) {
    &-info {
      padding-left: 0;
    }

    &-title {
      @include typo-rwd(stapel-step-up-2);
    }
  }

  @include mq(xl) {
    &-title {
      @include typo-rwd(stapel-step-up-3);
    }
  }

  @include mq(xxl) {
    &-title {
      @include typo-rwd(stapel-step-up-4);
    }

    .twitterTimeline {
      text-align: center;
    }
  }
}

// Blockquote

blockquote {
  margin: 0;
}

.blockquote {
  padding: 10px 12px;
  border-left: 2px solid $dark-blue-900;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  color: $gray-600;
}

// Charts

// Bar

.chartBar {
  padding: 10px;
  transition: all $general-change ease;
  border: 1px solid transparent;

  &:hover {
    box-shadow: $elevation1;
    border-color: $gray-100;
  }
  &-label {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    padding-right: 1.75rem;
  }

  &-bar {
    position: relative;
    padding-right: 1.75rem;
  }

  .icon {
    width: 1rem;
    height: 1rem;
    margin-top: 0.125rem;
  }

  .iconWrapper {
    position: absolute;
    bottom: -0.1875rem;
    right: 0;
    margin: 0;
  }

  .bar {
    width: 100%;
    height: 1rem;
    margin: 8px 12px 0 0;
    background: $gray-50;
    opacity: 0.9;
    box-shadow: inset 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.06);
  }

  .progress {
    background: $green-300;
    width: 100%;
    height: 100%;
    transform-origin: 0 0;
    animation: progress-animate ease-in-out 1200ms forwards;
  }

  @keyframes progress-animate {
    from {
      transform: scaleX(0);
    }
  }
}

// Gauge

.chartGauge {
  $self: &;
  text-align: center;
  width: 13.875rem;

  .gauge {
    position: relative;
    width: 13.875rem;
    height: 13.875rem;

    #{$self}-value {
      @include centered(absolute);
    }
  }

  &-value {
    @include typo-rwd(stapel-step-up-5);
    span {
      @include typo-rwd(stapel-step-up-1);
      display: inline-block;
      margin-left: 0.125rem;
      position: relative;
      top: -0.125rem;
    }
  }

  &-label {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-900;
    margin-top: 1.25rem;
  }
}

// Charts banner

.chartsBanner {
  background: $gray-50;
  text-align: center;
  padding: 2rem 1rem;

  .chartPanel {
    background: $white;
    padding: 1.5rem 0.625rem 1.5rem;
    border: 0.0625rem solid $gray-100;
    text-align: left;
  }

  .chartPanel-gauge {
    padding: 2.5rem 0.625rem;
    max-width: 22.5rem;
    margin: 0 auto;
  }

  .chartPanels {
    .chartPanel-gauge {
      max-width: none;
    }
  }

  .chartGauge {
    margin: 0 auto;
  }

  .stepUp1-title {
    text-align: center;
    margin-bottom: 1.25rem;
  }

  &-title {
    @include typo-rwd(stapel-step-up-3);
    margin-bottom: 1rem;
  }

  .chartPanels {
    .chartPanel {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include mq(md) {
    padding: 1.75rem 1rem 2rem;

    &-title {
      margin-bottom: 1.25rem;
    }

    .chartPanels {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .chartPanel {
        margin-bottom: 0;
        &:not(.chartPanel-gauge) {
          flex: 1 1 calc(50% - 1rem);
        }
        &:last-child {
          margin-left: 1rem;
        }
      }
      .chartPanel-gauge {
        width: 100%;
        max-width: none;
        margin: 0 0 1rem 0;
      }
    }
  }

  @include mq(lg) {
    padding: 3.25rem 1rem 5.25rem;
    .chartPanels {
      flex-wrap: nowrap;

      .chartPanel-gauge {
        flex: 1 1 auto;
        margin-bottom: 0;
      }

      .chartPanel {
        &:not(.chartPanel-gauge) {
          flex: 0 0 270px;
        }

        &:not(:last-child) {
          margin-right: 1rem;
        }

        &:last-child {
          margin-left: 0;
        }
      }

      &.only-bars {
        .chartPanel {
          flex-basis: 300px;
        }
      }
    }

    &-title {
      margin-bottom: 2.5rem;
    }
  }

  @include mq(xl) {
    &-title {
      @include typo-rwd(stapel-step-up-4);
    }

    .chartPanels {
      .chartPanel {
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }

  @include mq(xxl) {
    .chartPanels {
      .chartPanel {
        &:not(.chartPanel-gauge) {
          flex-basis: 26.25rem;
        }

        &:not(:last-child) {
          margin-right: 2.25rem;
        }
      }
    }
  }
}

// Detail phase

.detailPhases {
  padding: 2rem 1rem;
}

.detailPhase {
  max-width: calc(47.5rem + 2rem);
  margin: 0 auto;

  padding-bottom: 36px;

  &-title {
    @include typo-rwd(stapel-step-up-2);
    margin-bottom: 4px;
  }
  .playerList-labels {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      @include typo-rwd(dm-sans-step-down-2-bold-caps);
      text-transform: uppercase;
      color: $gray-400;
      letter-spacing: 0.0313rem;
    }
  }
}

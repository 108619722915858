// News detail

.newsDetail {
  padding: 2.5rem 1rem;
  color: $gray-600;

  &-header {
    .stepUp4-title {
      margin-bottom: 1rem;
      color: $gray-900;
    }
  }

  &-text {
    @include typo-rwd(dm-sans-step-up-1-regular);
    margin-bottom: 1.25rem;
  }

  &-extraInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 0.0625rem solid $gray-200;
    padding: 1rem 0;
  }

  &-date {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    padding-right: 0.75rem;
    margin: 0.125rem 0.75rem 0.125rem 0;
    position: relative;

    &:after {
      content: '';
      width: 0.125rem;
      height: 0.75rem;
      position: absolute;
      top: 0.1875rem;
      right: 0;
      background: $gray-300;
    }
  }

  &-author {
    @include typo-rwd(dm-sans-step-down-1-bold);
    margin: 0.125rem 0;
    span {
      font-weight: normal;
      margin-right: 0.375rem;
    }
  }

  &-share {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 0.0625rem solid $gray-200;
    border-bottom: 0.0625rem solid $gray-200;
    padding: 0.75rem 0 0.625rem;

    .label {
      margin-right: 1.25rem;
      @include typo-rwd(dm-sans-step-down-1-regular);
      margin-top: -0.25rem;
    }

    .socialItem {
      &:not(:last-child) {
        margin-right: 1rem;
      }

      .bg {
        fill: $gray-100;
        transition: fill $quick-change;
      }

      .shape {
        fill: $gray-900;
        transition: fill $quick-change;
      }

      &:hover {
        .bg {
          fill: $gray-900;
        }

        .shape {
          fill: $gray-100;
        }
      }
    }
  }

  &-image {
    margin: 0;
    width: 100%;
    margin: 2.25rem 0;

    img {
      width: 100%;
    }
  }

  &-caption {
    @include typo-rwd(dm-sans-step-down-2-regular);
    color: $gray-400;
  }

  .wysiwyg {
    padding: 0 0 0.125rem 0;

    &-paragraph {
      @include typo-rwd(dm-sans-step-up-1-regular);
    }
  }

  &-thumbnails {
    max-width: calc(60rem + 2rem);
    padding: 0 1rem;
    margin: 0 auto;

    .thumbnails {
      border-top: 0.0625rem solid $gray-200;
      padding: 1.25rem 0;
    }
  }

  &-label {
    @include typo-rwd(stapel-step-down-1-caps-bold);
    color: $gray-400;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  @include mq(md) {
    padding: 3.5rem 1rem;
    &-thumbnails {
      .thumbnails {
        display: flex;
        flex-wrap: wrap;
      }

      .articleThumbnail {
        flex: 0 0 50%;
        max-width: none;
      }
    }
  }

  @include mq(lg) {
    &-thumbnails {
      .articleThumbnail {
        flex-basis: 33.3%;
        max-width: none;
      }
    }
  }

  @include mq(xl) {
    &.innerWrapper {
      max-width: calc(54rem + 2rem);
    }
  }
}

.newsArticle {
  &.is-dark {
    background: $dark-blue-900;
    color: rgba($white, 0.87);

    .wysiwyg {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white;
      }
      p,
      li {
        color: inherit;
      }
    }
  }

  .innerWrapper {
    padding: 2.5rem 1rem;
    @include mq(md) {
      padding: 3.5rem 1rem;
    }
  }

  &-header {
    .stepUp5-title {
      margin-bottom: 1rem;
      color: $white;
    }
  }

  .wysiwyg {
    padding: 0 0 0.125rem 0;

    &-paragraph {
      @include typo-rwd(dm-sans-step-up-1-regular);
    }
  }

  &-actions {
    margin-top: 1.25rem;
    text-align: center;

    p {
      margin-top: 1.125rem;
    }
  }
}

// $----------------------------------
// $VARIABLES
// ------------------------------------

// Colors
$dark-blue-900: #000429;
$dark-blue-700: #09214b;
$dark-blue-400: #3a4a70;
$dark-blue-500: #172f5c;
$dark-blue-600: #112955;
$dark-blue-800: #03193f;
$red-800: #c70034;
$red-700: #d80033;
$red-600: #ed003e;
$red-400: #ff0043;
$red-50: #ffe8ee;
$blue-800: #0077c8;
$blue-600: #009bf0;
$blue-400: #00b7ff;
$blue-300: #00c3ff;
$blue-200: #6fd4ff;
$blue-25: #ebf9ff;
$purple-50: #f2e7ff;
$purple-100: #dcc4fe;
$purple-200: #c49cff;
$purple-400: #964bff;
$purple-500: #7f1bfd;
$purple-800: #5710b0;
$purple-900: #4a0e96;
$green-800: #00bb16;
$green-300: #1aff82;
$green-400: #00fb5d;
$green-200: #86ffaa;
$yellow-500: #ffec0d;
$yellow-700: #febf00;
$gray-900: #17191a;
$black: #000000;
$gray-600: #5d6466;
$gray-400: #8b9598;
$gray-300: #c3cbce;
$gray-200: #d8dfe1;
$gray-100: #ebeff1;
$gray-50: #f5f7f8;
$white: #ffffff;

// Fonts
$font-base: 16px;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$black: 900;

// Layout
$layout-maxWidth: 1024px;

$layout-maxWidth-xl: 1464px;

$size-sm: 420px;
$size-md: 600px;
$size-lg: 900px;
$size-xl: 1200px;
$size-xxl: 1560px;

// Breakpoints
$grid-breakpoints: (
  xs: 0px,
  // Extra small screen / phone
    sm: $size-sm,
  // Small screen / phone
    md: $size-md,
  // Medium screen / tablet
    lg: $size-lg,
  // Large screen / desktop
    xl: $size-xl,
  // Extra large screen / wide desktop
    xxl: $size-xxl // Extra large screen / wide desktop,,,,
);

$bp-sm: 26.25rem;
$elevation1: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
$elevation2: 0 0.0625rem 0.5rem 0.125rem rgba(0, 0, 0, 0.1);

// Portrait card

.portraitCard {
  $self: &;
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  border: 1px solid transparent;
  padding: 10px;
  position: relative;

  .portrait {
    flex: 0 0 auto;
  }

  &-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 48px;
  }

  &-info {
    flex: 1 1 auto;
    padding-left: 16px;
    color: $gray-900;
    min-width: 0;
  }

  &-title {
    @include typo-rwd(dm-sans-baseline-bold);
    margin-bottom: 6px;
  }

  &-platformName {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
    margin-bottom: 2px;
    min-width: 0;
    word-break: break-word;
  }

  &-userName {
    @include typo-rwd(dm-sans-step-down-1-regular);
    color: $gray-600;
    min-width: 0;
    word-break: break-word;
  }

  &-social {
    display: flex;
    margin-top: 18px;
  }

  &-nickname {
    @include typo-rwd(dm-sans-step-down-2-bold-caps);
    text-transform: uppercase;
  }

  .socialItem {
    margin-right: 16px;
    svg {
      width: 2.625rem;
      height: 2.625rem;
    }

    .bg {
      fill: $gray-100;
    }

    .shape {
      fill: $dark-blue-900;
    }

    &:hover {
      .bg {
        fill: $dark-blue-900;
      }

      .shape {
        fill: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $gray-100;
    box-shadow: $elevation1;
  }

  &.is-squared {
    align-items: center;
    &:hover {
      cursor: initial;
      box-shadow: none;
      border-color: transparent;
    }

    #{$self}-avatar {
      position: relative;
      overflow: hidden;
      flex-shrink: 0;
      width: 112px;
      height: 145px;

      .frame {
        position: relative;
        z-index: 2;
      }

      .player {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        object-fit: cover;
        @include centered(absolute);
      }
    }
  }

  @include mq(lg) {
    align-items: center;
    &-info {
      padding-left: 24px;
    }

    .socialItem {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
